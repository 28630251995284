import React from 'react';
import { useIdle } from 'react-use';
import { useNavigate} from "react-router-dom";
import { Navigate } from "react-router-dom";

const UserActivityDetector = () => {
  const isIdle = useIdle(300000);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isIdle) {
      localStorage.clear();
      navigate("/login");
    }
  }, [isIdle]);

  return (
    <div>
      
    </div>
  );
};

export default UserActivityDetector;
