import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// @mui
import { 
  Card,
  Stack,
  Button,
  Box,
  Container,
  Typography,
  Breadcrumbs, 
  TextField
 } from '@mui/material';
 import { Formik } from "formik";
 import { useTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// hooks
import useSettings from '../../hooks/useSettings';
import CustomSnackbar from "../../utils/CustomSnackbar";

// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------


const Medications_Doctor = () => {
  // const {id} = useParams()
  const location = useLocation();
  const [data, setData]= useState([])
  const [value, setValue] = React.useState(null);
  const [name1,setN1] = useState("")
  const [reaction1,setR1] = useState("")
  const [name2, setN2]= useState("")
  const [reaction2, setR2]= useState("")
  const [name3, setN3] = useState("")
  const [reaction3,setR3] = useState("")
  const [name4, setN4]= useState("")
  const [reaction4, setR4]= useState("")
  const isNonMobile = useMediaQuery("(min-width:600px)");
const theme = useTheme();
  const handleFormSubmit = (values) => {
    console.log(values);
  };
  const [openSnack, setOpenSnack] = useState({
    open: false,
    severity: null,
    message: null,
  });

  useEffect(() => {
    axios
      .post(
        "https://qakdci.dstinnovate.com/2222/fetchspatient",JSON.stringify({ patient_id: location.state.id })
      )
      .then((response) => {
        // console.log(id)
        console.log(response)
        setData(response.data);
        setN1(response.data[0].name1)
        setR1(response.data[0].reaction1)
        setN2(response.data[0].name2)
        setR2(response.data[0].reaction2)
        setN3(response.data[0].name3)
        setR3(response.data[0].reaction3)
        setN4(response.data[0].name4)
        setR4(response.data[0].reaction4)

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleSave(event, patient_id){
    event.preventDefault()
    axios.put('https://qakdci.dstinnovate.com/2222/medication-s5', {
        patient_id:location.state.id,
        name1:name1,
        reaction1:reaction1,
        name2:name2,
        reaction2:reaction2,
        name3:name3,
        reaction3:reaction3,
        name4:name4,
        reaction4:reaction4,  
    })
    .then(res => {
      console.log(res)
      {
        setOpenSnack({
          open: true,
          severity: "success",
          message: "Details Saved Successfully",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
        //   navigate("../");
        setOpenSnack({open: false})
        }, 3000);   
      } 
    })
    .catch(err => {
      console.log(err)
      {
        setOpenSnack({
          open: true,
          severity: "error",
          message: "Something went Wrong",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
          // navigate("../");
          setOpenSnack({open: false})
        }, 3000);   
      } 
    }
    )
}
  const { themeStretch } = useSettings();
  const { open, severity, message, position } = openSnack;

  return (
    <>
        {CustomSnackbar(open, severity, message, position)}

    <Helmet>
        <title> Medication | Kaizen Digital </title>
      </Helmet>
    <Page title="Page Two">
      <Container maxWidth={themeStretch ? false : 'xl'}>
      <Stack direction="column" spacing={0}>
          <Stack direction="column" spacing={0}>
          <div role="presentation">
    
    </div>

    <Box m="20px">
    <Card>
    <Box m="20px">
    <Helmet>
        <title> Medication | Kaizen Digital </title>
      </Helmet>

      <br />
            <Stack direction="column" spacing={0}>
          <Typography color="primary" variant="h4" gutterBottom>
          5. Medications and Supplements
          </Typography>
          
        </Stack>
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
         
        </Stack>
        <p>Please list all the medications that you are currently taking including vitamins and supplements.</p>
    </Box>
    <Box m="20px">
      

      <Formik
        onSubmit={handleFormSubmit}
        
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
            <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
                    <TextField
                fullWidth
                id='sub'
                type="text"
                label="Substance/Medication"
                name="name1"
                value={name1}
                onChange={(e) => setN1(e.target.value)}
                sx={{ gridColumn: "span 2" }}
              />
             
              <TextField
                fullWidth
                id='sub'
                type="text"
                label="Reaction"
                value={reaction1}
                onChange={(e) => setR1(e.target.value)}
                sx={{ gridColumn: "span 2" }}
              />  
              <TextField
                fullWidth
                id='sub'
                type="text"
                label="Substance/Medication"
                name="prescription_name2"
                value={name2}
                onChange={(e) => setN2(e.target.value)}
                sx={{ gridColumn: "span 2" }}
              />
             
              <TextField
                fullWidth
                id='sub'
                type="text"
                label="Reaction"
                value={reaction2}
                onChange={(e) => setR2(e.target.value)}
                sx={{ gridColumn: "span 2" }}
              />  
              <TextField
                fullWidth
                id='sub'
                type="text"
                label="Substance/Medication"
                name="prescription_name2"
                value={name3}
                onChange={(e) => setN3(e.target.value)}
                sx={{ gridColumn: "span 2" }}
              />
             
              <TextField
                fullWidth
                id='sub'
                type="text"
                label="Reaction"
                value={reaction3}
                onChange={(e) => setR3(e.target.value)}
                sx={{ gridColumn: "span 2" }}
              />  
              <TextField
                fullWidth
                id='sub'
                type="text"
                label="Substance/Medication"
                name="prescription_name2"
                value={name4}
                onChange={(e) => setN4(e.target.value)}
                sx={{ gridColumn: "span 2" }}
              />
             
              <TextField
                fullWidth
                id='sub'
                type="text"
                label="Reaction"
                value={reaction4}
                onChange={(e) => setR4(e.target.value)}
                sx={{ gridColumn: "span 2" }}
              />  

              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Date"
        variant="filled"
        value={value}
        sx={{ gridColumn: "span 2" }}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider> */}
  

    <Stack sx={{ gridColumn: "span 3" }}></Stack>
<Stack sx={{ gridColumn: "span 1" }}>
                <Button onClick={handleSave} variant='contained' sx={{color:"white"}}> SAVE PATIENT DETAILS</Button>
                </Stack>

            </Box>
          </form>
        )}
      </Formik>
    </Box>
    </Card> 
  </Box>

     </Stack>
     
         
        </Stack>
      </Container>
    </Page>
    </>
  );
}



export default Medications_Doctor;
