import React from 'react'

const Sleep = () => {
  return (
    <div>
      Sleep
    </div>
  )
}

export default Sleep
