import { useState } from "react";
import { Helmet } from 'react-helmet-async';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';

import { Link } from "react-router-dom";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  Typography,
  Breadcrumbs,
  useTheme,
  Card,
  Button,
  Checkbox
} from "@mui/material";
import { Stack } from "@mui/system";

// hooks
import useSettings from '../../hooks/useSettings';

// components





const Appointment_Patient = ({handleAppointment}) => {
  
  const { themeStretch } = useSettings();
  const theme = useTheme();

  const [currentEvents, setCurrentEvents] = useState([]);

  const handleDateClick = (selected) => {
  const title = prompt("Please enter a new title for your event");
  const calendarApi = selected.view.calendar;
  calendarApi.unselect();

    if (title) {
      calendarApi.addEvent({
        id: `${selected.dateStr}-${title}`,
        title,
        start: selected.startStr,
        end: selected.endStr,
        allDay: selected.allDay,
      });
    }
  };

  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

  const doctor = [
    'Dr. Mohammed Ahmed',
    'Dr. Hossein Ansari',
    'Dr. Colin Liu',
    'Dr. Jennifer Sumner',
  ];

  const handleEventClick = (selected) => {
    if (
      window.confirm(
        `Are you sure you want to delete the event '${selected.event.title}'`
      )
    ) {
      selected.event.remove();
    }
  };

  const [personName, setPersonName] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

 
  // const handleAppointment = ()=>{
  //   console.log("appointment done");
  // }

  
  return (
   
   

   <Card>
    <Box m="20px">
    <Helmet>
        <title> Appointments | Kaizen Digital </title>
      </Helmet>

      
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h3" gutterBottom color="primary">
            Appointments
          </Typography>
        </Stack>
        <div role="presentation" >
      <Breadcrumbs aria-label="breadcrumb">
      <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          
Dashboard</Typography>
        
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          
Appointments</Typography>
      </Breadcrumbs>
    </div>
    <br />
    </Box>
    
                  <br />
   <Box m="20px">

      <Box display="flex" justifyContent="space-between">
        {/* CALENDAR */}
        <Box flex="1 1 100%" ml="15px">
          <FullCalendar
            height="75vh"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            initialView="dayGridMonth"
            editable
            selectable
            selectMirror
            dayMaxEvents
            select={handleDateClick}
            eventClick={handleEventClick}
            eventsSet={(events) => setCurrentEvents(events)}
            
          />
        </Box>
      </Box>
    </Box>
    </Card>
    
 );
};

export default Appointment_Patient;
