import React from 'react'

const Apathy = () => {
  return (
    <div>
      Apathy
    </div>
  )
}

export default Apathy
