import {
  Box,
  Card,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useTheme } from "@mui/material/styles";
import useSettings from "../../hooks/useSettings";

import { useLocation, useNavigate } from "react-router-dom";
import { Button, Container } from "@mui/material";
import { useEffect, useState } from "react";

const EditProgram = () => {
  const [nm, setNm] = useState("");
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [forms, setForms] = useState([]);
  const [rows, setRows] = useState([]);
  const [description, setDescription] =useState("");
  const [status, setStatus] = useState("");
  const [icon, setIcon] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://test1.dstinnovate.com/fetchprogram",
        JSON.stringify({ id: location.state.id })
      )
      .then((response) => {
        setNm(response.data.program_name);
        const filteredForms = response.data.forms.split(",").filter(Boolean);
        setForms(filteredForms);
        setDescription(response.data.description);
        setStatus(response.data.status);
        setIcon(response.data.icon);
      })
      .catch((err) => {
        console.log(err);
      });

    axios.get("https://test1.dstinnovate.com/editassignform").then((res) => {
      setRows(res.data);
      console.log(res);
    });
  }, [location.state.id]);

  console.log(forms);

  const permission = (id) => {
    return forms.includes(id);
  };

  const handleCheckBox = (id) => {
    if (forms.includes(id)) {
      setForms(forms.filter((item) => item !== id));
    } else {
      setForms([...forms, id]);
    }
  };

  const handleSave = async () => {
    try {
      const obj = {
        id: location.state.id,
        program_name: nm,
        forms: forms,
        description: description,
        status: status,
        icon: icon
      };
      await axios.post(
        "https://test1.dstinnovate.com/editprogram",
        JSON.stringify(obj)
      );
      navigate("../");
    } catch (error) {
      console.error("Error saving program:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title> Program | Kaizen Digital </title>
      </Helmet>
      <center>
        <Typography color="primary" variant="h4" sx={{ mb: 5 }}>
          ASSIGN FORMS TO A PROGRAM{" "}
        </Typography>
      </center>
      <Card>
        <Box m="20px">
          <Formik initialValues={{}} validationSchema={checkoutSchema}>
            {({ errors, touched }) => (
              <form>
                <Box>
                  <Container>
                    <Typography color="primary" variant="h4">
                      {nm}
                    </Typography>
                  </Container>
                </Box>
                <br />
                <br />
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">FORM NAME</TableCell>
                        <TableCell align="left">PERMISSIONS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row" align="left">
                            {row.form_name}
                          </TableCell>
                          <TableCell align="left">
                            <Checkbox
                              checked={permission(row.id)}
                              onChange={() => handleCheckBox(row.id)}
                            />
                            {row.link_name}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <br />
                <br />

                <Box align="center">
                  <Button
                    variant="contained"
                    sx={{ color: "white" }}
                    onClick={handleSave}
                  >
                    SAVE
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Card>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  selectdoctor: yup.string().required(),
});

export default EditProgram;
