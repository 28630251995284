import { memo } from "react";
// @mui
import { styled } from "@mui/material/styles";
import { Container, AppBar } from "@mui/material";
// config
import { HEADER } from "../../../config";
// components
import { NavSectionHorizontal } from "../../../components/nav-section";
//
import navConfig from "./NavConfig";
import useDetails from "../../../hooks/useDetails";
import { useState, useEffect } from "react";
import { navBarItems } from "../../../routes";
import { filteredNavConfig } from "./FilterNavItems";

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  transition: theme.transitions.create("top", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  width: "100%",
  position: "fixed",
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(1, 0),
  boxShadow: theme.customShadows.z8,
  top: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

function NavbarHorizontal() {
  // added filtered Nav items
  const details = useDetails();
  const [filteredNavItems, setFilteredNavItems] = useState([]);
  useEffect(() => {
    const navItems = navBarItems(details.permission).map(
      (item) => `/dashboard/${item}`
    );
    setFilteredNavItems(filteredNavConfig(navItems, navConfig));
  }, []);

  return (
    <RootStyle>
      <Container maxWidth={false}>
        <NavSectionHorizontal navConfig={filteredNavItems} />
      </Container>
    </RootStyle>
  );
}

export default memo(NavbarHorizontal);