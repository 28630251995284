import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

// @mui
import { 
  Card,
  Stack,
  Box,
  Button,
  Container,
  Typography,
  Breadcrumbs, 
 TextField
 } from '@mui/material';
 import { Formik } from "formik";
 import CustomSnackbar from "../../utils/CustomSnackbar";

 import { useTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// hooks
import useSettings from '../../hooks/useSettings';

// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------

const Prescription = () => {
  const location = useLocation();
  // const {id} = useParams()
  const [data, setData]= useState([])
  const [value, setValue] = React.useState(null);
  const [prescription_name1,setPName1] = useState("")
  const [prescription_dosage1,setPDose1] = useState("")
  const [prescription_frequency1, setPFreq1]= useState("")
  const [prescription_any_side_effects1, setPSideEff1]= useState("")

  const [prescription_name2,setPName2] = useState("")
  const [prescription_dosage2,setPDose2] = useState("")
  const [prescription_frequency2, setPFreq2]= useState("")
  const [prescription_any_side_effects2, setPSideEff2]= useState("")

  const [prescription_name3,setPName3] = useState("")
  const [prescription_dosage3,setPDose3] = useState("")
  const [prescription_frequency3, setPFreq3]= useState("")
  const [prescription_any_side_effects3, setPSideEff3]= useState("")

  const [prescription_name4,setPName4] = useState("")
  const [prescription_dosage4,setPDose4] = useState("")
  const [prescription_frequency4, setPFreq4]= useState("")
  const [prescription_any_side_effects4, setPSideEff4]= useState("")

  const isNonMobile = useMediaQuery("(min-width:600px)");
const theme = useTheme();
  const handleFormSubmit = (values) => {
    console.log(values);
  };
  const [openSnack, setOpenSnack] = useState({
    open: false,
    severity: null,
    message: null,
  });
  useEffect(() => {
    axios
      .post(
        "https://qakdci.dstinnovate.com/2222/fetchspatient",JSON.stringify({ patient_id: location.state.id })
      )
      .then((response) => {
        // console.log(id)
        // console.log(response)
        setData(response.data);
        setPName1(response.data[0].prescription_name1)
        setPDose1(response.data[0].prescription_dosage1)
        setPFreq1(response.data[0].prescription_frequency1)
        setPSideEff1(response.data[0].prescription_any_side_effects1)

        setPName2(response.data[0].prescription_name2)
        setPDose2(response.data[0].prescription_dosage2)
        setPFreq2(response.data[0].prescription_frequency2)
        setPSideEff2(response.data[0].prescription_any_side_effects2)

        setPName3(response.data[0].prescription_name3)
        setPDose3(response.data[0].prescription_dosage3)
        setPFreq3(response.data[0].prescription_frequency3)
        setPSideEff3(response.data[0].prescription_any_side_effects3)

        setPName4(response.data[0].prescription_name4)
        setPDose4(response.data[0].prescription_dosage4)
        setPFreq4(response.data[0].prescription_frequency4)
        setPSideEff4(response.data[0].prescription_any_side_effects4)

        // setRF(response.data[0].relative_friend);

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleSave(event, patient_id){
    event.preventDefault()
    axios.put('https://qakdci.dstinnovate.com/2222/prescription-s6', {
        patient_id:location.state.id,
        prescription_name1:prescription_name1,
        prescription_dosage1:prescription_dosage1,
        prescription_frequency1:prescription_frequency1,
        prescription_any_side_effects1:prescription_any_side_effects1,
        
        prescription_name2:prescription_name2,
        prescription_dosage2:prescription_dosage2,
        prescription_frequency2:prescription_frequency2,
        prescription_any_side_effects2:prescription_any_side_effects2,

        prescription_name3:prescription_name3,
        prescription_dosage3:prescription_dosage3,
        prescription_frequency3:prescription_frequency3,
        prescription_any_side_effects3:prescription_any_side_effects3,
        
        prescription_name4:prescription_name4,
        prescription_dosage4:prescription_dosage4,
        prescription_frequency4:prescription_frequency4,
        prescription_any_side_effects4:prescription_any_side_effects4,
        
        // relative_friend:relative_friend,
        
    })
    .then(res => {
      console.log(res)
      {
        setOpenSnack({
          open: true,
          severity: "success",
          message: "Details Saved Successfully",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
        //   navigate("../");
        setOpenSnack({open: false})
        }, 3000);   
      } 
    })
    .catch(err => {
      console.log(err)
      {
        setOpenSnack({
          open: true,
          severity: "error",
          message: "Something went Wrong",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
          // navigate("../");
          setOpenSnack({open: false})
        }, 3000);   
      } 
    }
    )
}

  
const { themeStretch } = useSettings();
  
const { open, severity, message, position } = openSnack;


  return (
    <>
        {CustomSnackbar(open, severity, message, position)}

    <Helmet>
        <title> Patients| Kaizen Digital </title>
      </Helmet>
    <Page title="Page Two">
      <Container maxWidth={themeStretch ? false : 'xl'}>
      <Stack direction="column" spacing={0}>
          <Stack direction="column" spacing={0}>
          {/* <Typography variant="h4" gutterBottom color="primary">
            Add Patient       
          </Typography> */}
          <div role="presentation" >
      {/* <Breadcrumbs aria-label="breadcrumb">
        <Typography 
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary">
          Dashboard
        </Typography>
        
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
Patients        </Typography>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
         Add Patient
        </Typography>
      </Breadcrumbs> */}
    </div>
    <Box m="20px">
    <Card>
    <Box m="20px">
    <Helmet>
        <title> Prescription | Kaizen Digital </title>
      </Helmet>

      <br />
            <Stack direction="column" spacing={0}>
          <Typography color="primary" variant="h4" gutterBottom>
          6. Prescription Medications
          </Typography>
          
        </Stack>
        
        <p>Please list any drug allergies below. if you do not have any drug allergies, please leave this section blank.</p>
    </Box>
    <Box m="20px">
      

      <Formik
        onSubmit={handleFormSubmit}
        
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
<form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
                    <TextField
                fullWidth
                id='sub'
                type="text"
                label="Name"
                // name="prescription_name1"
                value={prescription_name1}
                onChange={(e) => setPName1(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
                 <TextField
                fullWidth
                id='sub'
                type="text"
                label="Dosage"
                value={prescription_dosage1}
                onChange={(e) => setPDose1(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Frequency"
                value={prescription_frequency1}
                onChange={(e) => setPFreq1(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />    
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Any Side Effects"
                value={prescription_any_side_effects1}
                onChange={(e) => setPSideEff1(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />  
                 <TextField
                fullWidth
                id='sub'
                type="text"
                label="Name"
                // name="prescription_name1"
                value={prescription_name2}
                onChange={(e) => setPName2(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
                 <TextField
                fullWidth
                id='sub'
                type="text"
                label="Dosage"
                value={prescription_dosage2}
                onChange={(e) => setPDose2(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Frequency"
                value={prescription_frequency2}
                onChange={(e) => setPFreq2(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />    
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Any Side Effects"
                value={prescription_any_side_effects2}
                onChange={(e) => setPSideEff2(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />  
                 <TextField
                fullWidth
                id='sub'
                type="text"
                label="Name"
                // name="prescription_name1"
                value={prescription_name3}
                onChange={(e) => setPName3(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
                 <TextField
                fullWidth
                id='sub'
                type="text"
                label="Dosage"
                value={prescription_dosage3}
                onChange={(e) => setPDose3(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Frequency"
                value={prescription_frequency3}
                onChange={(e) => setPFreq3(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />    
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Any Side Effects"
                value={prescription_any_side_effects3}
                onChange={(e) => setPSideEff3(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />  
                 <TextField
                fullWidth
                id='sub'
                type="text"
                label="Name"
                // name="prescription_name4"
                value={prescription_name4}
                onChange={(e) => setPName4(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
                 <TextField
                fullWidth
                id='sub'
                type="text"
                label="Dosage"
                value={prescription_dosage4}
                onChange={(e) => setPDose4(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Frequency"
                value={prescription_frequency4}
                onChange={(e) => setPFreq4(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />    
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Any Side Effects"
                value={prescription_any_side_effects4}
                onChange={(e) => setPSideEff4(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />  
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Date"
        variant="filled"
        value={value}
        sx={{ gridColumn: "span 2" }}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider> */}

    <Stack sx={{ gridColumn: "span 3" }}></Stack>
<Stack sx={{ gridColumn: "span 1" }}>
                <Button onClick={handleSave} variant='contained' sx={{color:"white"}}> SAVE PATIENT DETAILS</Button>
                </Stack>

            </Box>
          </form>
        )}
      </Formik>
    </Box>
    </Card> 
  </Box>
     </Stack>
     
         
        </Stack>
      </Container>
    </Page>
    </>
  );
}



export default Prescription;
