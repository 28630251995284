import React from 'react'

const Aberrant = () => {
  return (
    <div>
      Aberrant
    </div>
  )
}

export default Aberrant
