// src/App.js
import React from 'react';
import Router from "./routes";
import ThemeProvider from "./theme";
import ThemeColorPresets from "./components/ThemeColorPresets";
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import Settings from "./components/settings";
import { ProgressBarStyle } from "./components/ProgressBar";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import { AuthProvider } from "./contexts/AuthProvider";
import UserActivityDetector from './UserActivityDetector';

export default function App() {
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <MotionLazyContainer>
            <ProgressBarStyle />
            <Settings />
            <ScrollToTop />
            <AuthProvider>
              <UserActivityDetector />
              <Router />
            </AuthProvider>
          </MotionLazyContainer>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
