import * as React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import useDetails from 'src/hooks/useDetails';

// @mui
import {
  Card,
  Stack,
  Button,
  Box,
  Container,
  Typography,
  IconButton,
  Breadcrumbs,
} from "@mui/material";

// hooks
import useSettings from "../../hooks/useSettings";

// components
import Page from "../../components/Page";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { rowSelectionStateInitializer } from "@mui/x-data-grid/internals";

// ----------------------------------------------------------------------

export default function Patients_Doctor() {
  const [d_id, setDId] = useState("") //static doctor id
  const { themeStretch } = useSettings();
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const userDetails = useDetails(); 
  let uid = userDetails?.user_id ; 
  // console.log("userID passed",uid );

  const handleView = (id) => {
    navigate("/dashboard/mypatients/view", { state: { id } });
  };

  const actionBtn = (id) => {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton
          aria-label="add"
          size="medium"
          onClick={() => handleView(id)}
        >
          <VisibilityIcon fontSize="inherit" />
        </IconButton>
      </Stack>
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 140,
    },
    {
      field: "lastname",
      headerName: "Lastname",
      width: 140,
    },
    {
      field: "mobile",
      headerName: "Contact",
      width: 120,
    },
    {
      field: "email",
      headerName: "Email",
      width: 240,
    },
    {
      field: "dob",
      headerName: "Date Of Birth",
      width: 160,
    },

    {
      field: "gender",
      headerName: "Gender",
      width: 100,
      valueGetter: (params) => {
        if (params.value == "M" || params.value == "1") {
          return "Male";
        } else if (params.value == "F" || params.value == "2") {
          return "Female";
        } else if (params.value == "O" || params.value == "3") {
          return "Other";
        } else {
          return " ";
        }
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 160,
      renderCell: (params) => {
        let id = params.row.id;
        return actionBtn(id);
      },
    },
  ];


    axios
      .post(
        "https://test1.dstinnovate.com/doctorid-from-userid",JSON.stringify({ user_id:uid })
      )
      .then((res) => {
        // console.log("Fetched Response",res.data.d_id);
        setDId(res.data.d_id);
        // console.log("Doctor ID", d_id)
      })
      .catch((err) => {
        console.log(err);
      });


  useEffect(() => {
    axios
      .get("https://test3.dstinnovate.com/patientsviews")
      .then(function (response) {
        // console.log(response)
        const data = response.data;
        setRows(data);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title> Patients| Kaizen Digital </title>
      </Helmet>
      <Page title="Page Two">
        <Container maxWidth={themeStretch ? false : "xl"}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Stack direction="column" spacing={0}>
              <Typography variant="h3" gutterBottom color="primary">
                Patients
              </Typography>
              <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    color="text.primary"
                  >
                    Dashboard
                  </Typography>

                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    color="text.primary"
                  >
                    Patients
                  </Typography>
                </Breadcrumbs>
              </div>
            </Stack>
            <Stack direction="row" spacing={2}>

            </Stack>
          </Stack>

          <Card>
            <Box sx={{ width: "100%" }}>
              <DataGrid
                autoHeight
                slots={{ toolbar: GridToolbar }}
                rows={rows}
                // rows={rows.filter(row =>
                //   row.doctor_id.toLowerCase().includes(d_id)
                // )}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                  sorting: {
                    sortModel: [{ field: "id", sort: "desc" }],
                  },
                }}
                pageSizeOptions={[5, 10, 25]}
                // checkboxSelection
                disableRowSelectionOnClick
                loading={!rows.length}
              />
            </Box>
          </Card>
        </Container>
      </Page>
    </>
  );
}
