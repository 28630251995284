import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const Protected = ({ allowedRoles }) => {
  const location = useLocation();

  const localData = JSON.parse(localStorage.getItem("user"));
  const from = location.state?.from?.pathname || "/";

  // pushing back to unsecure pages
  return localData ? <Navigate to="/" state={{ from }} replace /> : <Outlet />;
};
export default Protected;
