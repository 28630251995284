import React, { useState } from "react";
import {
  TextField,
  Button,
  Stack,
  Typography,
  Box,
  Container,
  Card,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect } from "react";
import axios from "axios";
import useSettings from "../../hooks/useSettings";
import Page from "../../components/Page";
import CustomSnackbar from "../../utils/CustomSnackbar";
import Image from "../PatientsDasboard/Image";
import { useLocation } from "react-router-dom";

//Insurance Information starts here
const PatientInsuranceInfo_Doctor = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  // const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  // const [ipaSelectedOption, setIpaSelectedOption] = useState("");
  const [ipa, setIpa] = useState("false");
  // const [insOptions, setInsOptions] = useState("");
  const { themeStretch } = useSettings();
  // const { id } = useParams();
  // const [pay, setPAY] = useState("");
  const [paymode_status, setPStatus] = useState("");
  const [ii, setII] = useState("");
  const [sbn, setSBN] = useState("");
  const [ibd, setIBD] = useState("");
  const [ig, setIG] = useState("");
  const [pn, setPN] = useState("");
  const [si, setSI] = useState(" ");
  // const [irs, setIRS] = useState("")
  // const [ipa, setIPA] = useState("")
  const [ps, setPS] = useState("");
  const [frontImage, setFI] = useState();
  const [backImage, setBI] = useState();

  const [istatus, setIstatus] = useState("");

  // const handleChange1 = (event) => {
  //   setInsOptions(event.target.value);
  // };

  const navigate = useNavigate();
  const [openSnack, setOpenSnack] = useState({
    open: false,
    severity: null,
    message: null,
  });
  const location = useLocation();

  // const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .post(
        "https://qakdci.dstinnovate.com/2222/fetchspatient",
        JSON.stringify({ patient_id: location.state.id })
      )
      .then((response) => {
        // console.log("hello");
        // console.log(response);
        setII(response.data[0].insurance_information);
        setSBN(response.data[0].subscriber_name);
        setIBD(response.data[0].insurance_birth_date);
        setIG(response.data[0].insurance_group);
        setPN(response.data[0].insurance_policy_no);
        setSelectedOption(response.data[0].insurance_relationship_subscriber);
        setPS(response.data[0].p_specify);
        setPStatus(response.data[0].paymode_status);
        setSI(response.data[0].secodary_insurance);
        setFI(response.data[0].front_side_upload);
        setBI(response.data[0].back_side_upload);

        // setIstatus(response.data.insurance_status);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleSave(event) {
    event.preventDefault();
    const data = {
      patient_id: location.state.id,
      insurance_information: ii,
      subscriber_name: sbn,
      insurance_birth_date: ibd,
      insurance_group: ig,
      insurance_policy_no: pn,
      insurance_relationship_subscriber: selectedOption,
      ipa: ipa,
      p_specify: ps,
      paymode_status: paymode_status,
      secodary_insurance: si,
      front_side_upload: frontImage,
      back_side_upload: backImage,
    };
    const formData = new FormData();
    const entries = Object.entries(data);
    entries.map(([key, value]) => {
      formData.append(key, value);
    });

    axios
      .post(
        "https://qakdci.dstinnovate.com/2222/patient-insurance-info-s2",
        formData
      )
      .then((res) => {
        console.log(res);
        {
          setOpenSnack({
            open: true,
            severity: "success",
            message: "Details Saved Successfully",
            position: ["bottom", "center"],
          });
          setTimeout(() => {
            //   navigate("../");
            setOpenSnack({ open: false });
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const { open, severity, message, position } = openSnack;

  return (
    <>
      {CustomSnackbar(open, severity, message, position)}
      <Page title="Page Two">
        <Container maxWidth={themeStretch ? false : "xl"}>
          <Box>
            <Box m="20px">
              <Card>
                <Box m="20px">
                  <Stack direction="column" spacing={0}>
                    <Typography color="primary" variant="h4" gutterBottom>
                      2. Insurance Information 2 :
                    </Typography>
                  </Stack>

                  <br />
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="paymode_status">
                        Paymode Status
                      </InputLabel>
                      <Select
                        labelId="Paymode Status"
                        id="ms"
                        label="Paymode Status"
                        value={paymode_status}
                        onChange={(e) => setPStatus(e.target.value)}
                        sx={{ gridColumn: "span 2" }}
                        name="paymode_status"
                      >
                        <MenuItem value={"0"}>Insurance</MenuItem>
                        <MenuItem value={"1"}>Cash</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="insurance_information">
                        Insurance Information
                      </InputLabel>
                      <Select
                        labelId="Insurance Information"
                        id="ii"
                        label="Insurance Information"
                        value={ii}
                        onChange={(e) => setII(e.target.value)}
                        sx={{ gridColumn: "span 2" }}
                        name="insurance_information"
                      >
                        <MenuItem value={1}>Medicare</MenuItem>
                        <MenuItem value={2}>Tri-Care</MenuItem>
                        <MenuItem value={3}>Humana</MenuItem>
                        <MenuItem value={4}>Cigna</MenuItem>
                        <MenuItem value={5}>Anthem Blue Cross of CA</MenuItem>
                        <MenuItem value={6}>Blue Shield CA</MenuItem>
                        <MenuItem value={7}>United HealthCare</MenuItem>
                        <MenuItem value={8}>Aetna</MenuItem>
                        <MenuItem value={9}>Self Pay</MenuItem>
                        <MenuItem value={10}>Other</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      id="secodary_insurance"
                      name="secodary_insurance"
                      label="Other Insurance Details"
                      sx={{
                        gridColumn: "span 2",
                      }}
                      helperText="specify insurance information here (if not found in the list)"
                      value={si}
                      onChange={(e) => setSI(e.target.value)}
                    />
                    {/* comment the below br tag if secodary_insurance is uncommented */}
                    {/* <br /> */}
                    <TextField
                      id="subscribersname"
                      name="subscribersname"
                      label="Subscriber Name 1"
                      sx={{
                        gridColumn: "span 2",
                      }}
                      value={sbn}
                      onChange={(e) => setSBN(e.target.value)}
                    />

                    <TextField
                      id="insurance_birth_date"
                      name="insurance_birth_date"
                      label="Subscriber Date of Birth"
                      sx={{
                        gridColumn: "span 1",
                      }}
                      value={ibd}
                      onChange={(e) => setIBD(e.target.value)}
                    />

                    <FormControl fullWidth>
                      <InputLabel id="selectedOption">
                        Patient Relationship to Subscriber
                      </InputLabel>
                      <Select
                        labelId="Patient Relationship to Subscriber"
                        id="irs"
                        label="Patient Relationship to Subscriber"
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        sx={{ gridColumn: "span 1" }}
                        name="selectedOption"
                      >
                        <MenuItem value={"Self"}>Self</MenuItem>
                        <MenuItem value={"spouse"}>Spouse</MenuItem>
                        <MenuItem value={"child"}>Child</MenuItem>
                        <MenuItem value={"other"}>Other</MenuItem>
                      </Select>
                    </FormControl>

                    <TextField
                      id="groupno"
                      label="Enter Group Number"
                      sx={{
                        gridColumn: "span 2",
                      }}
                      value={ig}
                      onChange={(e) => setIG(e.target.value)}
                    />
                    <TextField
                      id="policyno"
                      label="Enter Policy Number"
                      sx={{
                        gridColumn: "span 2",
                      }}
                      value={pn}
                      onChange={(e) => setPN(e.target.value)}
                    />

                    {/* <TextField
              id="irs"
              label="Patient Relationship to Subscriber"
              sx={{
                gridColumn: "span ",
              }}
              name="irs"
              value={selectedOption}
              onChange={e => setSelectedOption(e.target.value)}
              /> */}

                    <TextField
                      id="ipa"
                      label="Are you part of IPA"
                      sx={{
                        gridColumn: "span ",
                      }}
                      name="ipa"
                      value={ipa}
                      onChange={(e) => setIpa(e.target.value)}
                    />
                    {/* <FormControl fullWidth>
                 <InputLabel id="ipa">Are you part of IPA</InputLabel>
                    <Select
                      labelId="Are you part of IPA"
                      id="ipa"
                      label="Are you part of IPA"
                      value={ipa}
                      onChange={e => setIpa(e.target.value)}
                      sx={{ gridColumn: "span 1" }}
                      name = "ipa"
                    >
                      <MenuItem value={"true"}>Yes</MenuItem>
                      <MenuItem value={"false"}>No</MenuItem>
                      </Select>
                  </FormControl> */}
                    <TextField
                      id="specify"
                      label="If yes, Specify"
                      sx={{
                        gridColumn: "span 2",
                      }}
                      name="ipaSpecification"
                      value={ps}
                      onChange={(e) => setPS(e.target.value)}
                    />

                    <br />
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Button
                        variant="contained"
                        component="label"
                        sx={{ color: "white" }}
                      >
                        Front Side Upload
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          type="file"
                          name="frontImage"
                          // value={values.frontImage}
                          onChange={(event) => {
                            setFI(event.currentTarget.files[0]);
                          }}
                        />
                      </Button>
                    </Stack>
                    <br />

                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Button
                        variant="contained"
                        component="label"
                        sx={{ color: "white" }}
                      >
                        Back Side Upload
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          type="file"
                          name="backImage"
                          // value={values.backImage}
                          onChange={(event) => {
                            setBI(event.currentTarget.files[0]);
                          }}
                        />
                      </Button>
                    </Stack>

                    <br />
                    <Stack>
                      <Image
                        src={`https://qakdci.dstinnovate.com/2222/${frontImage}`}
                      />
                    </Stack>

                    <Stack>
                      <Image
                        src={`https://qakdci.dstinnovate.com/2222/${backImage}`}
                      />
                    </Stack>

                    <Stack sx={{ gridColumn: "span 3" }}></Stack>
                    <Stack sx={{ gridColumn: "span 1" }}>
                      <Button
                        onClick={handleSave}
                        variant="contained"
                        sx={{ color: "white" }}
                      >
                        SAVE PATIENT DETAILS
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Box>
        </Container>
      </Page>
    </>
  );
};

const initialValues = {
  insurance_information: "",
  lastName: "",
  email: "",
  contact: "",
  dateofbirth: "",
  gender: "",
  maritalstatus: "",
  occupation: "",
  ssn: "",
  password: "",
  address1: "",
  address2: "",
  city: "",
  country: "",
  postal: "",
};

export default PatientInsuranceInfo_Doctor;
