import axios from "axios";

export default axios.create({
  baseURL: "https://qakdci.dstinnovate.com/",
  headers: {
    "Content-Type": "application/json",
  },
});



