import * as React from "react";
import { Box, Card, Typography,Button, Tabs, Tab, Breadcrumbs } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// hooks
import useSettings from "../../../hooks/useSettings";
import Delusions from "./Delusions";
import Hallucinations from "./Hallucinations";
import Agitation from "./Agitation";
import Depression from "./Depression";
import Anxiety from "./Anxiety";
import Elation from "./Elation";
import Apathy from "./Apathy";
import Disinhibition from "./Disinhibition";
import Aberrant from "./Aberrant";
import Sleep from "./Sleep";
import Appetite from "./Appetite";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NpiOriginal = () => {
  const { themeStretch } = useSettings();

  const [value, setValue] = React.useState(0);
  const tabCount = 12; // Total number of tabs


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNext = () => {
    setValue((prev) => Math.min(prev + 1, tabCount - 1));
  };

  const handleBack = () => {
    setValue((prev) => Math.max(prev - 1, 0));
  };


  return (
    <>
      <Helmet>
        <title> Neuropsychiatric Inventory (NPI) | Kaizen Digital </title>
      </Helmet>
      <Typography variant="h3" sx={{ mb: 1 }} color="primary" margin="15px">
        Neuropsychiatric Inventory (NPI)
      </Typography>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb" margin="15px">
          <Typography
            // underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
            // color="inherit"
            // to="/"
          >
            Dashboard
          </Typography>

          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            Neuropsychiatric Inventory (NPI):{" "}
          </Typography>
        </Breadcrumbs>
      </div>
      <br />
      <br />

      <Card>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Delusions" {...a11yProps(0)} />
              <Tab label="Hallucinations" {...a11yProps(1)} />
              <Tab label="Agitation/Aggression" {...a11yProps(2)} />
              <Tab label="Depression" {...a11yProps(3)} />
              <Tab label="Anxiety" {...a11yProps(4)} />
              <Tab label="Elation/Euphoria" {...a11yProps(5)} />
              <Tab label="Apathy/Indifference" {...a11yProps(6)} />
              <Tab label="Disinhibition" {...a11yProps(7)} />
              <Tab label="Irritability" {...a11yProps(8)} />
              <Tab label="Aberrant Motor Behavior" {...a11yProps(9)} />
              <Tab label="Sleep" {...a11yProps(10)} />
              <Tab label="Appetite and eating disorders" {...a11yProps(11)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Delusions />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Hallucinations />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Agitation />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Depression />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Anxiety />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Elation />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Apathy />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <Disinhibition />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <Aberrant />
          </TabPanel>
          <TabPanel value={value} index={9}>
            <Sleep />
          </TabPanel>
          <TabPanel value={value} index={10}>
            <Appetite />
          </TabPanel>
        </Box>

         {/* Navigation Buttons */}
         <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap:'8px', p: 2 }}>
         <Button
            variant="contained"
            color="primary"
            onClick={handleBack}
            disabled={value === 0} // Disable back button on first tab
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={value === tabCount - 1} // Disable next button on last tab
          >
            Next
          </Button>
        </Box>
      </Card>
    </>
  );
};


export default NpiOriginal;
