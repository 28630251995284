import React from 'react'

const Anxiety = () => {
  return (
    <div>
      Anxiety
    </div>
  )
}

export default Anxiety
