import React, { useState, useEffect } from "react";
import {
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  Button,
  Grid,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const DelusionsScreen = () => {
  const theme = useTheme();

  const [delusions, setDelusions] = useState(null);
  const [subQuestions, setSubQuestions] = useState({});
  const [ratings, setRatings] = useState({});

  // State variables for combined scores
  const [combinedFrequencyScore, setCombinedFrequencyScore] = useState(0);
  const [combinedSeverityScore, setCombinedSeverityScore] = useState(0);
  const [combinedDistressScore, setCombinedDistressScore] = useState(0);

  const handleDelusionsChange = (event, value) => {
    setDelusions(value);

    if (value === "no") {
      setSubQuestions({});
      setRatings({});
      resetScores(); // Reset combined scores
    }
  };

  const handleSubQuestionChange = (index) => (event, value) => {
    setSubQuestions((prev) => ({ ...prev, [index]: value }));

    if (value === "yes") {
      setRatings((prev) => ({
        ...prev,
        [index]: { frequency: "", severity: "", distress: "" },
      }));
    } else {
      setRatings((prev) => {
        const updated = { ...prev };
        delete updated[index];
        return updated;
      });
    }
  };

  const handleRatingChange = (index, type) => (event) => {
    const value = event.target.value;
    setRatings((prev) => ({
      ...prev,
      [index]: { ...prev[index], [type]: value },
    }));
  };

  const resetScores = () => {
    setCombinedFrequencyScore(0);
    setCombinedSeverityScore(0);
    setCombinedDistressScore(0);
  };

  // const handleFormSubmit = async (values) => {
  //   try {
  //     const response = await axios.post("https://test1.dstinnovate.com/insertDelusionScreening", {
  //       in_danger: subQuestions[0] === "yes" ? 1 : 0,
  //       in_danger_f: ratings[0]?.frequency || null,
  //       in_danger_s: ratings[0]?.severity || null,
  //       in_danger_d: ratings[0]?.distress || null,

  //       are_stealing: subQuestions[1] === "yes" ? 1 : 0,
  //       are_stealing_f: ratings[1]?.frequency || null,
  //       are_stealing_s: ratings[1]?.severity || null,
  //       are_stealing_d: ratings[1]?.distress || null,

  //       spouse_affair: subQuestions[2] === "yes" ? 1 : 0,
  //       spouse_affair_f: ratings[2]?.frequency || null,
  //       spouse_affair_s: ratings[2]?.severity || null,
  //       spouse_affair_d: ratings[2]?.distress || null,

  //       guest_living: subQuestions[3] === "yes" ? 1 : 0,
  //       guest_living_f: ratings[3]?.frequency || null,
  //       guest_living_s: ratings[3]?.severity || null,
  //       guest_living_d: ratings[3]?.distress || null,

  //       not_claim: subQuestions[4] === "yes" ? 1 : 0,
  //       not_claim_f: ratings[4]?.frequency || null,
  //       not_claim_s: ratings[4]?.severity || null,
  //       not_claim_d: ratings[4]?.distress || null,

  //       no_house: subQuestions[5] === "yes" ? 1 : 0,
  //       no_house_f: ratings[5]?.frequency || null,
  //       no_house_s: ratings[5]?.severity || null,
  //       no_house_d: ratings[5]?.distress || null,

  //       plan_abandon: subQuestions[6] === "yes" ? 1 : 0,
  //       plan_abandon_f: ratings[6]?.frequency || null,
  //       plan_abandon_s: ratings[6]?.severity || null,
  //       plan_abandon_d: ratings[6]?.distress || null,

  //       mag_fig: subQuestions[7] === "yes" ? 1 : 0,
  //       mag_fig_f: ratings[7]?.frequency || null,
  //       mag_fig_s: ratings[7]?.severity || null,
  //       mag_fig_d: ratings[7]?.distress || null,

  //       unusual_thing: subQuestions[8] === "yes" ? 1 : 0,
  //       unusual_thing_f: ratings[8]?.frequency || null,
  //       unusual_thing_s: ratings[8]?.severity || null,
  //       unusual_thing_d: ratings[8]?.distress || null,

  //       patient_id: values.p_id,
  //       submit_date: values.submitdate,
  //     });

  //     // Handle successful response
  //     setData(response.data);
  //     setOpenSnack({
  //       open: true,
  //       severity: "success",
  //       message: "Form Submitted Successfully",
  //     });

  //     // Navigate to another page after a brief delay
  //     setTimeout(() => {
  //       navigate("../myhealthrecord/delusionscreening");
  //     }, 2000);

  //   } catch (error) {
  //     // Handle errors
  //     setOpenSnack({
  //       open: true,
  //       severity: "error",
  //       message: "Something went wrong",
  //     });
  //     console.error("Error submitting form:", error);
  //   }
  // };

  // Calculate combined scores

  useEffect(() => {
    let frequencyScore = 0;
    let severityScore = 0;
    let distressScore = 0;

    Object.keys(ratings).forEach((index) => {
      const rating = ratings[index];
      if (rating.frequency) {
        frequencyScore += parseInt(rating.frequency); // Frequency values are from 1 to 4
      }
      if (rating.severity) {
        severityScore += parseInt(rating.severity); // Severity values are from 1 to 3
      }
      if (rating.distress) {
        distressScore += parseInt(rating.distress); // Distress values are from 0 to 5
      }
    });

    setCombinedFrequencyScore(frequencyScore);
    setCombinedSeverityScore(severityScore);
    setCombinedDistressScore(distressScore);
  }, [ratings]);

  const subQuestionsArray = [
    "1. Does the patient believe that he/she is in danger?",
    "2. Does the patient believe that others are stealing from him/her?",
    "3. Does the patient believe that his/her spouse is having an affair?",
    "4. Does the patient believe that unwelcome guests are living in his/her house?",
    "5. Does the patient believe that his/her spouse or others are not who they claim to be?",
    "6. Does the patient believe that his/her house is not his/her home?",
    "7. Does the patient believe that family members plan to abandon him/her?",
    "8. Does the patient believe that television or magazine figures are actually present in the home?",
    "9. Does the patient believe any other unusual things?",
  ];

  console.log(ratings[0]?.frequency);
  console.log(ratings[0]?.severity);
  console.log(ratings[0]?.distress);

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      style={{ padding: "0px 20px" }}
    >
      <Grid item>
        <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
          Delusions Screening
        </Typography>
      </Grid>

      <Grid item>
        <FormControl component="fieldset">
          <Typography variant="h6">Does the patient have delusions?</Typography>
          <ToggleButtonGroup
            exclusive
            value={delusions}
            onChange={handleDelusionsChange}
            aria-label="delusions"
          >
            <ToggleButton
              value="yes"
              sx={{
                color: theme.palette.primary.main,
                "&.Mui-selected": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
            >
              Yes
            </ToggleButton>
            <ToggleButton
              value="no"
              sx={{
                color: theme.palette.primary.main,
                "&.Mui-selected": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
            >
              No
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
      </Grid>

      {delusions === "yes" && (
        <Grid item>
          <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
            Follow-up Questions
          </Typography>
          {subQuestionsArray.map((question, index) => (
            <Grid container key={index} alignItems="center">
              <Grid item xs={8}>
                <Typography
                  sx={{
                    fontWeight: "bold", // Make text bold
                    fontSize: "15px",
                  }}
                >
                  {question}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <ToggleButtonGroup
                  exclusive
                  value={subQuestions[index]}
                  onChange={handleSubQuestionChange(index)}
                  aria-label={`sub-question-${index}`}
                >
                  <ToggleButton
                    value="yes"
                    sx={{
                      color: theme.palette.primary.main,
                      "&.Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      },
                    }}
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    value="no"
                    sx={{
                      color: theme.palette.primary.main,
                      "&.Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      },
                    }}
                  >
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>

              {subQuestions[index] === "yes" && (
                <Grid container spacing={2} item xs={12}>
                  {/* Frequency */}
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Typography variant="subtitle1">Frequency</Typography>
                      <Select
                        value={ratings[index]?.frequency || ""}
                        onChange={handleRatingChange(index, "frequency")}
                        displayEmpty
                      >
                        <MenuItem value="">Select frequency</MenuItem>
                        <MenuItem value="1">
                          1 - Occasionally - less than once per week
                        </MenuItem>
                        <MenuItem value="2">
                          2 - Often - about once per week
                        </MenuItem>
                        <MenuItem value="3">
                          3 - Frequently - several times per week but less than
                          every day
                        </MenuItem>
                        <MenuItem value="4">
                          4 - Very frequently - once or more per day
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Severity */}
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Typography variant="subtitle1">Severity</Typography>
                      <Select
                        value={ratings[index]?.severity || ""}
                        onChange={handleRatingChange(index, "severity")}
                        displayEmpty
                      >
                        <MenuItem value="">Select severity</MenuItem>
                        <MenuItem value="1">
                          1 - Mild - delusions present but seem harmless and
                          produce little distress
                        </MenuItem>
                        <MenuItem value="2">
                          2 - Moderate - delusions are distressing and
                          disruptive
                        </MenuItem>
                        <MenuItem value="3">
                          3 - Marked - delusions are very disruptive and a major
                          source of behavioral disruption
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Distress */}
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Typography variant="subtitle1">Distress</Typography>
                      <Select
                        value={ratings[index]?.distress || ""}
                        onChange={handleRatingChange(index, "distress")}
                        displayEmpty
                      >
                        <MenuItem value="">Select distress</MenuItem>
                        <MenuItem value="0">0 - Not at all</MenuItem>
                        <MenuItem value="1">1 - Minimally</MenuItem>
                        <MenuItem value="2">2 - Mildly</MenuItem>
                        <MenuItem value="3">3 - Moderately</MenuItem>
                        <MenuItem value="4">4 - Severely</MenuItem>
                        <MenuItem value="5">
                          5 - Very severely or extremely
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ))}
          {/* Display Combined Scores */}
          <Grid item>
            <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
              Delusion Scores:
            </Typography>
            <Button variant="h6">
              Frequency Score: {combinedFrequencyScore}
            </Button>
            <Button variant="h6">
              Severity Score: {combinedSeverityScore}
            </Button>
            <Button variant="h6">
              Distress Score: {combinedDistressScore}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DelusionsScreen;
