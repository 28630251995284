import {
    Box,
    Card,
    Button,
    TextField,
    Typography,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Breadcrumbs,
  } from "@mui/material";
  import { Formik, Field } from "formik";
  import * as yup from "yup";
  import useMediaQuery from "@mui/material/useMediaQuery";
  import { useTheme } from "@mui/material/styles";
  import { Helmet } from "react-helmet-async";
  import { useNavigate, Link } from "react-router-dom";
  import * as React from "react";
  import axios from "axios";
  import CustomSnackbar from "../../utils/CustomSnackbar";
  import { useState } from "react";
  
  import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
  
  import '@react-pdf-viewer/core/lib/styles/index.css';
  import '@react-pdf-viewer/default-layout/lib/styles/index.css'; 
  
  import { Worker } from "@react-pdf-viewer/core";
  
  
  
  // hooks
  import useSettings from "../../hooks/useSettings";
  
  const NewProgram = () => {
    const [n, setN] = useState("");
    const [d, setD] = useState("");
    const [i, setI] = useState("");
    const [s, setS] = useState("");
    const { themeStretch } = useSettings();
    const navigate = useNavigate();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const theme = useTheme();
    const [openSnack, setOpenSnack] = useState({
      open: false,
      severity: null,
      message: null,
    });

    const handleFormSubmit = async (values) => {
      try {
        const response = await axios.post("https://test3.dstinnovate.com/insertapi_program", {
          "prog_name":n,
          "description":d,
          "icon":i,
          "status":s
        }
        );

        setOpenSnack({
          open: true,
          severity: "success",
          message: "Program Created Successfully",
        });

        setTimeout(() => {
          navigate("../");
        }, 2000);
        console.log(response)
      } catch (error) {
        setOpenSnack({
          open: true,
          severity: "error",
          message: "Something went wrong",
        });
      }
    };

    const { open, severity, message } = openSnack;
  
    return (
      <>
        {CustomSnackbar(open, severity, message)}
        <Helmet>
          <title> Program add | Kaizen Digital </title>
        </Helmet>
        <Typography variant="h4" sx={{ mb: 1 }} color="primary">
          Create New Program
        </Typography>
        <div role="presentation" >
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
            //   underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              to="/"
            >
              Dashboard
            </Typography>
            <Typography
            //   underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
            //   to=""
            >
              Programs
            </Typography>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              Create New Program
            </Typography>
          </Breadcrumbs>
        </div>
        <br />{" "}
        <Card>
          <Box m="20px">
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Program Name"
                      onBlur={handleBlur}
                      onChange={e => setN(e.target.value)}
                      value={n}
                      name="programname"
                      // error={!!touched.programname && !!errors.programname}
                      helperText={touched.programname && errors.programname}
                      sx={{ gridColumn: "span 4" }}
                    />
  
                    {
                      <TextField
                        id="outlined-multiline-static"
                        variant="filled"
                        label="Description"
                        onBlur={handleBlur}
                        onChange={e => setD(e.target.value)}
                        value={d}
                        name="d"
                        // error={!!touched.d && !!errors.d}
                        multiline
                        rows={4}
                        helperText={"Enter Program Description"}
                        sx={{ gridColumn: "span 4" }}
                      />
                    }
                    {
                      <TextField
                        fullWidth
                        id="outlined-multiline-static1"
                        variant="filled"
                        type="text"
                        label="Icon"
                        onBlur={handleBlur}
                        onChange={e => setI(e.target.value)}
                        value={i}
                        name="icon"
                        // error={!!touched.icon && !!errors.icon}
                        helperText={
                          "Get icon name icon page (ex : far fa-user-md)"
                        }
                        sx={{ gridColumn: "span 4" }}
                      />
                    }
  
                    {
                      <FormControl fullWidth>
                        <InputLabel id="status">Status</InputLabel>
                        <Select
                          label="Status"
                          id="outlined-multiline-static2"
                          onBlur={handleBlur}
                          onChange={e => setS(e.target.value)}
                          name="status"
                        //   error={!!touched.status && !!errors.status}
                          sx={{ gridColumn: "span 4" }}
                          value={s}
                        >
                          <MenuItem value={"Enabled"}>Enabled</MenuItem>
                          <MenuItem value={"Disabled"}>Disabled</MenuItem>
                        </Select>
                      </FormControl>
                    }
                  </Box>  
                  <Box display="flex" justifyContent="end" mt="20px">
                    <Button type="submit" color="teal" variant="contained" sx={{color: "white"}} onClick={handleFormSubmit}>
                      SAVE
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Card>
      </>
    );
  };
  
  const checkoutSchema = yup.object().shape({
    programname: yup.string().required("required"),
    d: yup.string().required("required"),
    icon: yup.string().required("required"),
    status: yup.string().required("required"),
  });
  const initialValues = {
    programname: "",
    d: "",
    icon: "",
    status: "",
  };
  
  export default NewProgram;
  