import { Box, Card, Button, TextField, Typography, InputLabel, MenuItem, FormControl, Select, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, IconButton } from "@mui/material";
import { Formik} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';

import { Helmet } from "react-helmet-async";
import * as React from 'react';
// hooks
import useSettings from '../../hooks/useSettings';

 


const NewCarePlan_Doctor = () => {

    const { themeStretch } = useSettings();

    const isNonMobile = useMediaQuery("(min-width:600px)");
const theme = useTheme();
  const handleFormSubmit = (values) => {
    console.log(values);
  };
  const [doctor, setDoctor] = React.useState('');
  const [frequency, setFrequency] = React.useState('');
  const [duration, setDuration] = React.useState('');


  const handleChanging = (event) => {
    setDoctor(event.target.value);
  };
  const handleChanging1 = (event) => {
    setFrequency(event.target.value);
  };
  const handleChanging2 = (event) => {
    setDuration(event.target.value);
  };
  return (
   
   <>
    <Helmet>
        <title> Careplan add | Kaizen Digital </title>
    </Helmet>
    <Typography variant="h4" sx={{ mb: 5 }} color="primary" margin="15px">
          Add Care Plan
    </Typography>


   <Card>
   <Box m="20px">
      

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                type="text"
                label="Patient name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.patientname}
                name="patientname"
                error={!!touched.patientname && !!errors.patientname}
                helperText={touched.patientname && errors.patientname}
                sx={{ gridColumn: "span 1" }}
              />
              
              <TextField
                fullWidth
                type="text"
                label="Patient Email Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 2" }}
              />
               
              <FormControl fullWidth>
                 <InputLabel id="selectdoctor">Select Doctor</InputLabel>
                    <Select
                      labelId="selectdoctor"
                      id="selectdoctor"
                      label="Selct Doctor"
                      error={!!touched.selectdoctor && !!errors.selectdoctor}
                      helperText={touched.selectdoctor && errors.selectdoctor}
                      onChange={handleChanging}
                      sx={{ gridColumn: "span 3" }}
                      value={doctor}
                    >
                      <MenuItem value={0}>Dr. Mohammed Ahmed</MenuItem>
                      <MenuItem value={1}>Dr. Ansari</MenuItem>
                      <MenuItem value={2}>Dr. Colin Liu</MenuItem>
                      <MenuItem value={3}>Dr. Jennifer Sumner</MenuItem>

                    </Select>
                  </FormControl>
                  </Box>
                  <br />
                  <TableContainer >
      <Table sx={{ minWidth: 650 }} aria-label="simple table" border="1px">
        <TableHead>
          
        </TableHead>
        <TableBody >
        <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >            
            <TableCell><strong>Drug Name</strong></TableCell>
            <TableCell><strong>Generic</strong></TableCell>
            <TableCell><strong>Frequency</strong></TableCell>
            <TableCell><strong>Duration*</strong></TableCell>
            <TableCell><strong>Instruction</strong></TableCell>
            <TableCell></TableCell>

            </TableRow>
            <TableRow>
              <TableCell><TextField
                fullWidth
                type="text"
                label="Medicine Name"
              /> </TableCell>
              <TableCell>
              <TextField
                fullWidth
                type="text"
                label="Generic"
              />    
              </TableCell>
              <TableCell>
              <FormControl fullWidth>
                 <InputLabel id="selectdoctor"></InputLabel>
                    <Select
                      value={frequency}
                      onChange={handleChanging1}

                  
                    >
                      <MenuItem value={0}>1-0-0</MenuItem>
                      <MenuItem value={1}>1-0-1</MenuItem>
                      <MenuItem value={2}>1-1-1</MenuItem>
                      <MenuItem value={3}>0-0-1</MenuItem>
                      <MenuItem value={4}>0-1-0</MenuItem>

                    </Select>
                  </FormControl>
              </TableCell>
              <TableCell>
              <FormControl fullWidth>
                 <InputLabel id="selectdoctor"></InputLabel>
                    <Select
                      value={duration}
                      onChange={handleChanging2}

                  
                    >
                      <MenuItem value={0}>1 Day</MenuItem>
                      <MenuItem value={1}>2 Days</MenuItem>
                      <MenuItem value={2}>3 Days</MenuItem>
                      <MenuItem value={3}>4 Days</MenuItem>
                      <MenuItem value={4}>5 Days</MenuItem>
                      <MenuItem value={5}>6 Days</MenuItem>
                      <MenuItem value={6}>7 Days</MenuItem>
                      <MenuItem value={7}>8 Days</MenuItem>
                      <MenuItem value={8}>9 Days</MenuItem>
                      <MenuItem value={9}>10 Days</MenuItem>
                      <MenuItem value={10}>15 Days</MenuItem>
                      <MenuItem value={11}>20 Days</MenuItem>
                      <MenuItem value={12}>30 Days</MenuItem>
                      <MenuItem value={13}>60 Days</MenuItem>
                    </Select>
                  </FormControl>
              </TableCell>
              <TableCell>
              <TextField
                fullWidth
                type="text"
                label="Instruction"
              />  
              </TableCell>
              <TableCell>
              <IconButton aria-label="delete" size="meduim">
      <DeleteIcon fontSize="inherit" />
  </IconButton>
              </TableCell>
            </TableRow>
            </TableBody>
            </Table>
            </TableContainer>
            <br />
            <TextField 
            multiline
            type="text"
            label="Clinical Notes"
            sx={{width: "100%"}} rows={8}/>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" variant="contained" >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
    </Card>
    </>
  );
};



const checkoutSchema = yup.object().shape({
  patientname: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  selectdoctor: yup.string().required(),

});
const initialValues = {
  patientname: "",
  email:"",
  selectdoctor:""
};

export default NewCarePlan_Doctor;
