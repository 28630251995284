import * as React from 'react';
import { Helmet } from 'react-helmet-async';
// import { Link } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// @mui
import { 
  Card,
  Stack,
  Button,
  Box,
  MenuItem,
  Container,
  Grid,
  Typography,
  Breadcrumbs, 
  TextField, InputLabel, FormControl, Select
 } from '@mui/material';
 import { Formik } from "formik";
 import * as yup from "yup";
 import { useTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomSnackbar from "../../utils/CustomSnackbar";

// hooks
import useSettings from '../../hooks/useSettings';
import { useLocation } from "react-router-dom";

// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------

const AddPatient1_Doctor = () => {

  const {id} = useParams()
  const [value, setValue] = React.useState(null);

  const isNonMobile = useMediaQuery("(min-width:600px)");
const theme = useTheme();
  const handleFormSubmit = (values) => {
    console.log(values);
  };

  
  // const [gender, setGender] = React.useState('');
  const [openSnack, setOpenSnack] = useState({
    open: false,
    severity: null,
    message: null,
  });

  // const handleChanging = (event) => {
  //   setGender(event.target.value);
  // };
  const { themeStretch } = useSettings();
  const [data, setData] = useState([]);
  const [fn, setFN] = useState("");
  const [ln, setLN] = useState("")
  const [em, setEM] = useState("");
  const [mb, setMB] = useState("");
  const [dob, setDOB] = useState("");
  const [gen, setGEN] = useState("");
  const [oe, setOE] = useState("")
  const [ssn, setSSN] = useState("")
  const [ms, setMS] = useState("")
  const [adr, setADR] = useState("")
    const [adr1, setADR1] = useState("")
  const [adr2, setADR2] = useState("")
  const [adr3, setADR3] = useState("")
  const [adr4, setADR4] = useState("")
  const [adr5, setADR5] = useState("")
  const location = useLocation();
  // console.log(location.state)

  useEffect(() => {
    axios
      .post(
        "https://qakdci.dstinnovate.com/2222/fetchspatient",JSON.stringify({ patient_id: location.state.id  })
      )
      .then((response) => {
        console.log(id)
        console.log(response)
        setData(response.data);
        setFN(response.data[0].firstname);
        setLN(response.data[0].lastname);
        setEM(response.data[0].email);
        setMB(response.data[0].mobile);
        setDOB(response.data[0].dob);
        setGEN(response.data[0].gender);
        setOE(response.data[0].occupation_employer);
        setSSN(response.data[0].ssn);
        setMS(response.data[0].marital_status);
        // setADR(response.data[0].address);
        setADR1(response.data[0].p_street_address)
        setADR2(response.data[0].p_address_line2)
        setADR3(response.data[0].p_city)
        setADR4(response.data[0].p_state)
        setADR5(response.data[0].p_post_code)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleSave(event, patient_id){
    event.preventDefault()
    axios.put('https://qakdci.dstinnovate.com/2222/add-patient-s1', {
        patient_id:location.state.id,
        firstname: fn,
        lastname: ln,
        mobile: mb,
        email:em,
        dob:dob,
        gender:gen,
        marital_status: ms,
        occupation_employer: oe,
        ssn: ssn,
        // address: adr,
        p_street_address: adr1,
        p_address_line2: adr2,
        p_city: adr3,
        p_state: adr4,
        p_post_code:adr5,
    })
    .then(res => {
      console.log(res)
      {
        setOpenSnack({
          open: true,
          severity: "success",
          message: "Details Saved Successfully",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
        //   navigate("../");
        setOpenSnack({open: false})
        }, 3000);   
      } 
    })
    .catch(err => {
      console.log(err)
      {
        setOpenSnack({
          open: true,
          severity: "error",
          message: "Something went Wrong",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
          // navigate("../");
          setOpenSnack({open: false})
        }, 3000);   
      } 
    }
    )
}
const { open, severity, message, position } = openSnack;
  
  return (
    <>
    {CustomSnackbar(open, severity, message, position)}
    <Helmet>
        <title> Patient Registration | Kaizen Digital </title>
      </Helmet>
    <Page title="Page Two">
      <Container maxWidth={themeStretch ? false : 'xl'}>
      <Stack direction="column" spacing={0}>
          <Stack direction="column" spacing={0}>
          {/* <Typography variant="h4" gutterBottom color="primary">
            Add Patient       
          </Typography> */}
          <div role="presentation" >
      {/* <Breadcrumbs aria-label="breadcrumb">
        <Typography 
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary">
          Dashboard
        </Typography>
        
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
Patient        </Typography>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
         Add Patient
        </Typography>
      </Breadcrumbs> */}
    </div>
    <Box m="20px">
    <Card>
   

    <Box m="20px">
    <Helmet>
        <title> Add Patient_1 | Kaizen Digital </title>
      </Helmet>

      <br />
        <Stack direction="column" spacing={0}>
          <Typography color="primary" variant="h4" gutterBottom>
            1. Personal Information :
          </Typography>
          
        </Stack>

    </Box>
    <Box m="20px">
      

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                type="text"
                label="First Name*"
                
                // onBlur={handleBlur}
                // onChange={handleChange}
                value={fn}
                onChange={e => setFN(e.target.value)}
                name="firstName"
                // error={!!touched.firstName && !!errors.firstName}
                // helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 1" }}
              />
              
              <TextField
                fullWidth
                type="text"
                label="Last Name*"
                // onBlur={handleBlur}
                value={ln}
                onChange={e => setLN(e.target.value)}
                name="lastName"
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                fullWidth
                type="text"
                label="Email*"
                // disabled
                // onBlur={handleBlur}
                onChange={e => setEM(e.target.value)}
                value={em}
                name="email"
                // error={!!touched.email && !!errors.email}
                // helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                type="text"
                label="Enter Mobile Number*"
                
                // onBlur={handleBlur}
                onChange={e => setMB(e.target.value)}
                value={mb}
                name="contact"
                // error={!!touched.contact && !!errors.contact}
                // helperText={touched.contact && errors.contact}
                sx={{ gridColumn: "span 2" }}
              />

                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Date Of Birth"
        variant="filled"
        value={value}
        sx={{ gridColumn: "span 2" }}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider> */}
               
{/*              
              <FormControl fullWidth>
                 <InputLabel id="gender">Gender</InputLabel>
                    <Select
                      labelId="gender"
                      id="gender"
                      value={gender}
                      label="Gender"
                      onChange={handleChanging}
                      sx={{ gridColumn: "span 2" }}
                    >
                      <MenuItem value={1}>Male</MenuItem>
                      <MenuItem value={2}>Female</MenuItem>
                      <MenuItem value={3}>Other</MenuItem>
                    </Select>
                  </FormControl> */}

               <TextField
          fullWidth
          type="text"
          label="Date of Birth"
          // disabled
          onBlur={handleBlur}
          name="dob"
          // onChange={handleChange}
          value={dob || " "}
          
          sx={{ gridColumn: "span 1" }}
        />
        {/* <TextField
          fullWidth
          type="text"
          label="Gender"
          onBlur={handleBlur}
          name="gender"
          // disabled
          value={gen}
          onChange={e => setGEN(e.target.value)}
          sx={{ gridColumn: "span 1" }}
        />  */}
        <FormControl fullWidth>
                 <InputLabel id="gender">Gender</InputLabel>
                    <Select
                      labelId="Gender"
                      id="gender"
                      label="Gender"
                      value={gen}
                      onChange={e => setGEN(e.target.value)}
                      sx={{ gridColumn: "span 1" }}
                      name = "gender"
                    >
                      <MenuItem value={"M"}>Male</MenuItem>
                      <MenuItem value={"F"}>Female</MenuItem>
                      <MenuItem value={"O"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  {/* <TextField
                fullWidth
                type="text"
                label="Marital Status"
                
                // onBlur={handleBlur}
                onChange={e => setMS(e.target.value)}
                value={ms}
                name="maritalstatus"
                sx={{ gridColumn: "span 1" }}
                /> */}
                <FormControl fullWidth>
                 <InputLabel id="ms">Marital Status</InputLabel>
                    <Select
                      labelId="Marital Status"
                      id="ms"
                      label="Marital Status"
                      value={ms}
                      onChange={e => setMS(e.target.value)}
                      sx={{ gridColumn: "span 1" }}
                      name = "marital_status"
                    >
                      <MenuItem value={"S"}>Single</MenuItem>
                      <MenuItem value={"M"}>Married</MenuItem>
                      <MenuItem value={"D"}>Divorced</MenuItem>
                      <MenuItem value={"W"}>Widowed</MenuItem>
                    </Select>
                  </FormControl>
          
               <TextField
                fullWidth
                type="text"
                label="Occupation & Employer"
                // onBlur={handleBlur}
                value={oe}
                onChange={e => setOE(e.target.value)}
                
                name="occupation"
                sx={{ gridColumn: "span 1" }}
                />
                <TextField
                fullWidth
                type="text"
                
                label="SSN (optional)"
                // onBlur={handleBlur}
                onChange={e => setSSN(e.target.value)}
                value={ssn}
                name="ssn"
                sx={{ gridColumn: "span 1" }}
                />
              {/* <TextField
                fullWidth
                type="text"
                label="Address"
                value={adr}
                name="address"
                onChange={e => setADR(e.target.value)}
                sx={{ gridColumn: "span 2" }}
              /> */}

              <TextField
                fullWidth
                type="text"
                label="Number/House Name"
                value={adr1}
                name="address1"
                onChange={e => setADR1(e.target.value)}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                type="text"
                label="Street"
                value={adr2}
                name="address2"
                onChange={e => setADR2(e.target.value)}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                type="text"
                label="City"
                value={adr3}
                name="city"
                onChange={e => setADR3(e.target.value)}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                type="text"
                label="Country"
                value={adr4}
                name="country"
                onChange={e => setADR4(e.target.value)}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                type="text"
                label="Postcode"
                value={adr5}
                name="postcode"
                onChange={e => setADR5(e.target.value)}
                sx={{ gridColumn: "span 1" }}
              />
             
             
<Stack sx={{ gridColumn: "span 3" }}></Stack>
<Stack sx={{ gridColumn: "span 1" }}>
                <Button onClick={handleSave} variant='contained' sx={{color:"white"}}> SAVE PATIENT DETAILS</Button>
                </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
    </Card> 
  </Box>
     </Stack>
     
         
        </Stack>
      </Container>
    </Page>
    </>
  );
}

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  dateofbirth: yup.string().required("required"),
  password: yup
    .string()
    .required("required")
    .min(8, 'Password must be 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol'),
  gender: yup.string().required(),
  maritalstatus: yup.string(), 
  occupation: yup.string(),
  ssn : yup.string(),
  // address1: yup.string().required("required"),
  // address2: yup.string().required("required"),
  city: yup.string(),
  country: yup.string(),
  postal: yup.string(),

});
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  contact: "",
  dateofbirth: "",
  gender:"",
 maritalstatus:"",
 occupation:"",
 ssn: "", 
  password: "",
  address1: "",
  address2: "",
  city: "",
  country: "",
  postal: "",
};

export default AddPatient1_Doctor;
