import { Box, Card, Button, TextField, Typography, InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import { Formik} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';

import { Helmet } from "react-helmet-async";
import { useNavigate } from 'react-router-dom';
import * as React from 'react';
// hooks
import useSettings from '../../hooks/useSettings';




const EditCarePlan_Doctor = () => {

    const { themeStretch } = useSettings();

    const isNonMobile = useMediaQuery("(min-width:600px)");
const theme = useTheme();
  const handleFormSubmit = (values) => {
    console.log(values);
  };
  const [doctor, setDoctor] = React.useState('');

  const handleChanging = (event) => {
    setDoctor(event.target.value);
  };
  return (
   
   <>
    <Helmet>
        <title> Careplan add | Kaizen Digital </title>
    </Helmet>
    <Typography variant="h4" sx={{ mb: 5 }} color="primary" margin="15px">
          Edit Care Plan
    </Typography>


   <Card>
   <Box m="20px">
      

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                type="text"
                label="Patient name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.patientname}
                name="patientname"
                error={!!touched.patientname && !!errors.patientname}
                helperText={touched.patientname && errors.patientname}
                sx={{ gridColumn: "span 1" }}
              />
              
              <TextField
                fullWidth
                type="text"
                label="Patient Email Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 2" }}
              />
               
              <FormControl fullWidth>
                 <InputLabel id="selectdoctor">Select Doctor</InputLabel>
                    <Select
                      labelId="selectdoctor"
                      id="selectdoctor"
                      label="Selct Doctor"
                      error={!!touched.selectdoctor && !!errors.selectdoctor}
                      helperText={touched.selectdoctor && errors.selectdoctor}
                      onChange={handleChanging}
                      sx={{ gridColumn: "span 3" }}
                      value={doctor}
                    >
                      <MenuItem value={0}>Dr. Mohammed Ahmed</MenuItem>
                      <MenuItem value={1}>Dr. Ansari</MenuItem>
                      <MenuItem value={2}>Dr. Colin Liu</MenuItem>
                      <MenuItem value={3}>Dr. Jennifer Sumner</MenuItem>

                    </Select>
                  </FormControl>
                 
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" variant="contained" >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
    </Card>
    </>
  );
};



const checkoutSchema = yup.object().shape({
  patientname: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  selectdoctor: yup.string().required(),

});
const initialValues = {
  patientname: "",
  email:"",
  selectdoctor:""
};

export default EditCarePlan_Doctor;
