import { Box, Card, Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "src/api/axios";
import encryptPassword from "../../utils/encAlg";
import { Snackbar, Alert } from '@mui/material';


// Icons to be used for visual feedback
const getIcon = (condition) => {
  return condition ? (
    <CheckCircleIcon sx={{ color: "lime" }} />
  ) : (
    <CancelIcon sx={{ color: "red" }} />
  );
};

const CreatePassword = () => {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,  
    specialChar: false,
    match: false,
  });

  const handlePasswordChange = (password, confirmPassword) => {
    setPasswordStrength({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar: /[@$!%*?&#]/.test(password), 
      match: password === confirmPassword,
    });
  };

  // const handleFormSubmit = async (values) => {
  //   const obj = { email: values.email, password: encryptPassword(values.password) };
  //   console.log(obj);
  //   // const encryptedValues = {
  //   //   ...values,
  //   //   password: encryptPassword(values.password),
  //   //   username: encryptPassword(values.username),
  //   // };
  //   try {
  //     const response = await axios.post("https://test2.dstinnovate.com/resetpassword1", obj);
      
  //     if (response.status === 200 && response.data.emailValid) {
  //       console.log("submitted");
  //       navigate("../login");
  //     } else {
  //       console.error("Invalid email or unsuccessful request");
  //     }
  //   } catch (err) {
  //     console.error("Something went wrong", err);
  //   }
  // }
  const handleFormSubmit = async (values) => {
    const obj = { email: values.email, password: encryptPassword(values.password) };
      console.log(obj);
    try {
      const response = await axios.post("https://test2.dstinnovate.com/resetpassword1", obj);
      console.log(response.data);
      if (response.data.success_code === 200) {
        console.log("submitted");
        navigate("../login");
      } else {
        setErrorMessage('Invalid email address');
        setOpenSnackbar(true);
      }
    } catch (err) {
      console.error("Something went wrong", err);
      setErrorMessage('Something went wrong. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Helmet>
        <title> Create Password | Kaizen Digital </title>
      </Helmet>
      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <div style={{ backgroundImage: 'url("https://wallpaperaccess.com/full/1900913.jpg")', height: "100%" }}>
        <center style={{ padding: "50px" }}>
          <img src="https://clinical.dstinnovate.com/public/images/logo.png" alt="Logo" />
        </center>
        <center>
          <Card style={{ width: "600px", padding: "50px" }}>
            <Typography color="primary" variant="h4" sx={{ mb: 5 }}>
              Create Password
            </Typography>

            <Formik
              initialValues={initialValues}
              validationSchema={checkoutSchema}
              onSubmit={handleFormSubmit}
            >
              {({ values, handleBlur, handleChange, handleSubmit, isValid, dirty }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="email"
                    label="Enter your Email Address"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    sx={{ mb: 2 }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="password"
                    label="Enter your Password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      handlePasswordChange(e.target.value, values.confirmPassword);
                    }}
                    value={values.password}
                    sx={{ mb: 2 }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="password"
                    label="Confirm your Password"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      handlePasswordChange(values.password, e.target.value);
                    }}
                    value={values.confirmPassword}
                    sx={{ mb: 2 }}
                  />

                  {/* Password strength indicators */}
                  <Box>
                    <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      {getIcon(passwordStrength.length)}
                      &nbsp; Minimum 8 characters
                    </Typography>
                    <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      {getIcon(passwordStrength.uppercase)}
                      &nbsp; At least 1 uppercase character
                    </Typography>
                    <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      {getIcon(passwordStrength.lowercase)}
                      &nbsp; At least 1 lowercase character
                    </Typography>
                    <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      {getIcon(passwordStrength.number)}
                      &nbsp; At least 1 number
                    </Typography>
                    <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    {getIcon(passwordStrength.specialChar)} {/* Added this line */}
                    &nbsp; At least 1 special character (@, #, $, etc.)
                     </Typography>
                    <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      {getIcon(passwordStrength.match)}
                      &nbsp; Passwords match
                    </Typography>
                  </Box>

                  <Button type="submit" variant="contained" sx={{ mt: 3 }} disabled={!isValid || !dirty}>
                    Create Password
                  </Button>
                  {/* <Link to="/login" style={{ color: "teal", textDecoration: "none", display: "block", marginTop: "10px" }}>
                    <Typography>Remember Password?</Typography>
                  </Link> */}
                </form>
              )}
            </Formik>
          </Card>
        </center>
      </div>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Required"),
  password: yup
    .string()
    .required("Required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[@$!%*?&#]/, "Password must contain at least one special character"),
  confirmPassword: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const initialValues = {
  email: "",
  password: "",
  confirmPassword: "",
};

export default CreatePassword;
