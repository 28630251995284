import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// @mui
import { 
  Card,
  Stack,
  Button,
  Box,
  Container,
  Typography,
  Breadcrumbs, 
  TextField
 } from '@mui/material';
 import { Formik } from "formik";
 import { useTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// hooks
import useSettings from '../../hooks/useSettings';
import CustomSnackbar from "../../utils/CustomSnackbar";

// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------


const NonPrescription_Doctor = () => {
  // const {id} = useParams()
  const [value, setValue] = React.useState(null);
  const [data, setData]=useState([])
  const location = useLocation();
const [otc_name1, setOtcName1]=useState("")
const [otc_dosage1,setOtcDose1] = useState("")
const [otc_frequency1,setOtcFreq1] = useState("")
const [otc_any_side_effects1,setOtcSideEff1] = useState("")

const [otc_name2, setOtcName2]=useState("")
const [otc_dosage2,setOtcDose2] = useState("")
const [otc_frequency2,setOtcFreq2] = useState("")
const [otc_any_side_effects2,setOtcSideEff2] = useState("")

const [otc_name3, setOtcName3]=useState("")
const [otc_dosage3,setOtcDose3] = useState("")
const [otc_frequency3,setOtcFreq3] = useState("")
const [otc_any_side_effects3,setOtcSideEff3] = useState("")

const [otc_name4, setOtcName4]=useState("")
const [otc_dosage4,setOtcDose4] = useState("")
const [otc_frequency4,setOtcFreq4] = useState("")
const [otc_any_side_effects4,setOtcSideEff4] = useState("")

const [openSnack, setOpenSnack] = useState({
  open: false,
  severity: null,
  message: null,
});

  const isNonMobile = useMediaQuery("(min-width:600px)");
const theme = useTheme();
  const handleFormSubmit = (values) => {
    console.log(values);
  };

  useEffect(() => {
    axios
      .post(
        "https://qakdci.dstinnovate.com/2222/fetchspatient",JSON.stringify({ patient_id: location.state.id })
      )
      .then((response) => {
        // console.log(id)
        console.log(response)
        setData(response.data);
        setOtcName1(response.data[0].otc_name1)
        setOtcDose1(response.data[0].otc_dosage1)
        setOtcFreq1(response.data[0].otc_frequency1)
        setOtcSideEff1(response.data[0].otc_any_side_effects1)

        setOtcName2(response.data[0].otc_name2)
        setOtcDose2(response.data[0].otc_dosage2)
        setOtcFreq2(response.data[0].otc_frequency2)
        setOtcSideEff2(response.data[0].otc_any_side_effects2)

        setOtcName3(response.data[0].otc_name3)
        setOtcDose3(response.data[0].otc_dosage3)
        setOtcFreq3(response.data[0].otc_frequency3)
        setOtcSideEff3(response.data[0].otc_any_side_effects3)

        setOtcName4(response.data[0].otc_name4)
        setOtcDose4(response.data[0].otc_dosage4)
        setOtcFreq4(response.data[0].otc_frequency4)
        setOtcSideEff4(response.data[0].otc_any_side_effects4)

        // setRF(response.data[0].relative_friend);

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleSave(event, patient_id){
    event.preventDefault()
    axios.put('https://qakdci.dstinnovate.com/2222/non-prescription-s7', {
        patient_id:location.state.id,
        otc_name1:otc_name1,
        otc_dosage1:otc_dosage1,
        otc_frequency1:otc_frequency1,
        otc_any_side_effects1:otc_any_side_effects1,

        otc_name2:otc_name2,
        otc_dosage2:otc_dosage2,
        otc_frequency2:otc_frequency2,
        otc_any_side_effects2:otc_any_side_effects2,

        otc_name3:otc_name3,
        otc_dosage3:otc_dosage3,
        otc_frequency3:otc_frequency3,
        otc_any_side_effects3:otc_any_side_effects3,

        otc_name4:otc_name4,
        otc_dosage4:otc_dosage4,
        otc_frequency4:otc_frequency4,
        otc_any_side_effects4:otc_any_side_effects4,
        // relative_friend:relative_friend,
        
    })
    .then(res => {
      console.log(res)
      {
        setOpenSnack({
          open: true,
          severity: "success",
          message: "Details Saved Successfully",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
        //   navigate("../");
        setOpenSnack({open: false})
        }, 3000);   
      } 
    })
    .catch(err => {
      console.log(err)
      {
        setOpenSnack({
          open: true,
          severity: "error",
          message: "Something went Wrong",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
          // navigate("../");
          setOpenSnack({open: false})
        }, 3000);   
      } 
    }
    )
}


  const { themeStretch } = useSettings();
  

  const { open, severity, message, position } = openSnack;

  return (
    <>
        {CustomSnackbar(open, severity, message, position)}

    <Helmet>
        <title> Patients| Kaizen Digital </title>
      </Helmet>
    <Page title="Page Two">
      <Container maxWidth={themeStretch ? false : 'xl'}>
      <Stack direction="column" spacing={0}>
          <Stack direction="column" spacing={0}>
          {/* <Typography variant="h4" gutterBottom color="primary">
            Add Patient       
          </Typography> */}
          <div role="presentation" >
      {/* <Breadcrumbs aria-label="breadcrumb">
        <Typography 
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary">
          Dashboard
        </Typography>
        
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
Patients        </Typography>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
         Add Patient
        </Typography>
      </Breadcrumbs> */}
    </div>
    <Box m="20px">
    <Card>
   
    <Box m="20px">
    <Helmet>
        <title> OTC | Kaizen Digital </title>
      </Helmet>

      <br />
            <Stack direction="column" spacing={0}>
          <Typography color="primary" variant="h4" gutterBottom>
          7. Over The Counter/Non-Prescription
          </Typography>
          
        </Stack>
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h6" gutterBottom>
           Medications and Supplements
          </Typography>
        </Stack>
        <p>Please list any OTC & supplements below. If you are not currently taking any then you may leave this section blank.</p>
            </Box>
    <Box m="20px">
      

      <Formik
        onSubmit={handleFormSubmit}
        
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
            <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
                    <TextField
                fullWidth
                id='sub'
                type="text"
                label="Name"
                name="otc_name1"
                value={otc_name1}
                onChange={(e) => setOtcName1(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
                 <TextField
                fullWidth
                id='sub'
                type="text"
                label="Dosage"
                value={otc_dosage1}
                onChange={(e) => setOtcDose1(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Frequency"
                value={otc_frequency1}
                onChange={(e) => setOtcFreq1(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />    
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Any Side Effects"
                value={otc_any_side_effects1}
                onChange={(e) => setOtcSideEff1(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />  
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Name"
                name="otc_name1"
                value={otc_name2}
                onChange={(e) => setOtcName2(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
                 <TextField
                fullWidth
                id='sub'
                type="text"
                label="Dosage"
                value={otc_dosage2}
                onChange={(e) => setOtcDose2(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Frequency"
                value={otc_frequency2}
                onChange={(e) => setOtcFreq2(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />    
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Any Side Effects"
                value={otc_any_side_effects2}
                onChange={(e) => setOtcSideEff2(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />  
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Name"
                name="otc_name1"
                value={otc_name3}
                onChange={(e) => setOtcName3(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
                 <TextField
                fullWidth
                id='sub'
                type="text"
                label="Dosage"
                value={otc_dosage3}
                onChange={(e) => setOtcDose3(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Frequency"
                value={otc_frequency3}
                onChange={(e) => setOtcFreq3(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />    
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Any Side Effects"
                value={otc_any_side_effects3}
                onChange={(e) => setOtcSideEff3(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />  
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Name"
                name="otc_name1"
                value={otc_name4}
                onChange={(e) => setOtcName4(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
                 <TextField
                fullWidth
                id='sub'
                type="text"
                label="Dosage"
                value={otc_dosage4}
                onChange={(e) => setOtcDose4(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Frequency"
                value={otc_frequency4}
                onChange={(e) => setOtcFreq4(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />    
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Any Side Effects"
                value={otc_any_side_effects4}
                onChange={(e) => setOtcSideEff4(e.target.value)}
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
              />  
              
               {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Date"
        variant="filled"
        value={value}
        sx={{ gridColumn: "span 2" }}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider> */}
<br />
    <Stack sx={{ gridColumn: "span 2" }}></Stack>
<Stack sx={{ gridColumn: "span 1" }}>
                <Button onClick={handleSave} variant='contained' sx={{width:"220px",  color:"white"}}> SAVE PATIENT DETAILS</Button>
                </Stack>
            </Box>

            
            
          </form>
        )}
      </Formik>
    </Box>
    </Card> 
  </Box>
     </Stack>
     
         
        </Stack>
      </Container>
    </Page>
    </>
  );
}



export default NonPrescription_Doctor;
