import * as React from "react";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import {
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Table,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

// @mui
import {
  TextField,
  Card,
  Stack,
  MenuItem,
  Button,
  Container,
  Typography,
  Box,
  Breadcrumbs,
} from "@mui/material";

// hooks
import useSettings from "../../hooks/useSettings";

// components
import Page from "../../components/Page";
import { useEffect } from "react";
import { useState } from "react";

const AssignProgram = () => {
  const { themeStretch } = useSettings();
  const [rows, setRows] = useState([]);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    // { field: "patient_id", headerName: "Patient ID", width: 120 },
    {
      field: "p_name",
      headerName: "Patient Name",
      width: 350,
    },
    {
      field: "program_name",
      headerName: "Assigned Program",
      width: 350,
    },
    {
      field: "assigned_date",
      headerName: "Assigned Date",
      width: 350,
    },
  ];

  useEffect(() => {
    axios.get("https://test1.dstinnovate.com/assignedprograms?timestamp=" + new Date().getTime())
    .then((response) => {
        console.log(response.data);
        setRows(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title> Patient Vitals | Kaizen Digital </title>
      </Helmet>
      <Page title="Page Two">
        <Container maxWidth={themeStretch ? false : "xl"}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Stack direction="column" spacing={0}>
              <Typography variant="h3" gutterBottom color="primary">
                Assigned Programs
              </Typography>

              <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    color="text.primary"
                  >
                    Dashboard
                  </Typography>
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    color="text.primary"
                  >
                    Programs
                  </Typography>
                </Breadcrumbs>
              </div>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Link to="/dashboard/newprogram">
                <Button
                  type="submit"
                  variant="contained"
                  spacing={2}
                  sx={{ color: "white" }}
                >
                  Assign New Program
                </Button>
              </Link>
            </Stack>
          </Stack>

          <Card>
            <Box sx={{ overflow: "auto", width: "100%" }}>
              <DataGrid
                autoHeight
                slots={{ toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                  },
                }}
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 25]}
                disableRowSelectionOnClick
              />
            </Box>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default AssignProgram;