import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Button,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Checkbox,
  Select,
  FormControlLabel,
} from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as yup from "yup";
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CustomSnackbar from "../../utils/CustomSnackbar";

const EditDocBasicinfo = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [fn, setFN] = useState("");
  const [ln, setLN] = useState("");
  const [em, setEM] = useState("");
  const [mob, setMB] = useState("");
  const [status, setSt] = useState("");
  const [gen, setGen] = useState("");
  const [qua, setQua] = useState("");
  const [pic, setPic] = useState(null); // Change to null initially
  const [dep, setDep] = useState([]);
  const [about, setAb] = useState("");
  const [web, setWeb] = useState("");
  const [openSnack, setOpenSnack] = useState({
    open: false,
    severity: null,
    message: null,
  });
  const [data, setData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [departments, setDepartments] = useState([]);
  const [initiallyCheckedDep, setInitiallyCheckedDep] = useState([]);
  const [dynamicallyCheckedDep, setDynamicallyCheckedDep] = useState([]);

  useEffect(() => {
    axios
      .post(
        "https://test1.dstinnovate.com/fetchdoctor",
        JSON.stringify({ id: location.state.id })
      )
      .then((response) => {
        setData(response.data);
        setFN(response.data[0].firstname);
        setLN(response.data[0].lastname);
        setEM(response.data[0].email);
        setMB(response.data[0].mobile);
        setSt(response.data[0].status);
        setGen(response.data[0].gender);
        setDep(response.data[0].department_id);
        setQua(response.data[0].qualifications);
        setPic(response.data[0].picture);
        setAb(response.data[0].about);
        setWeb(response.data[0].website_link);
        setInitiallyCheckedDep(response.data[0].department_id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://test1.dstinnovate.com/departments")
      .then((response) => {
        setDepartments(response.data);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  const handleDynamicCheckboxChange = (departmentId, checked) => {
    if (checked) {
      setDynamicallyCheckedDep([...dynamicallyCheckedDep, departmentId]);
    } else {
      setDynamicallyCheckedDep(dynamicallyCheckedDep.filter(id => id !== departmentId));
    }
  };
  
  const handleFormSubmit = async (values) => {
    const selectedDepartments = departments.filter(department => initiallyCheckedDep.includes(department.id) || dynamicallyCheckedDep.includes(department.id));
    const idsString = selectedDepartments.map(department => department.id).join(',');
    
    const formData = new FormData();
    formData.append("id", location.state.id);
    formData.append("firstname", fn);
    formData.append("lastname", ln);
    formData.append("email", em);
    formData.append("mobile", mob);
    formData.append("status", status);
    formData.append("gender", gen);
    formData.append("qualifications", qua);
    formData.append("about", about);
    formData.append("website_link", web);
    formData.append("department_id", idsString);
    if (pic) {
      formData.append("picture", pic);
    }

    await axios
      .post("https://test1.dstinnovate.com/insertapieditdoctor", formData)
      .then((res) => {
        setOpenSnack({
          open: true,
          severity: "success",
          message: "Doctor Updated Successfully",
        });
        setTimeout(() => {
          navigate("../doctors");
        }, 2000);
      })
      .catch((err) => {
        setOpenSnack({
          open: true,
          severity: "error",
          message: "Something went wrong !",
        });
      });
};

  

  const { open, severity, message } = openSnack;

  return (
    <>
      {CustomSnackbar(open, severity, message, ["bottom", "right"])}
      <Box m="20px">
        <Card>
          <Box m="20px">
            <Helmet>
              <title> Edit Doctor | Kaizen Digital </title>
            </Helmet>
          </Box>
          <Box m="20px">
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      type="text"
                      label="First Name *"
                      onBlur={handleBlur}
                      onChange={e => setFN(e.target.value)}
                      value={fn}
                      name="firstName"
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      type="text"
                      label="Last Name *"
                      onBlur={handleBlur}
                      onChange={e => setLN(e.target.value)}
                      value={ln}
                      name="lastName"
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      type="text"
                      label="Email *"
                      onBlur={handleBlur}
                      onChange={e => setEM(e.target.value)}
                      value={em}
                      name="email"
                      sx={{ gridColumn: "span 1"
                    }}
                    />
                    <TextField
                      fullWidth
                      type="text"
                      label="Enter Mobile Number *"
                      onBlur={handleBlur}
                      onChange={e => setMB(e.target.value)}
                      value={mob}
                      name="contact"
                      sx={{ gridColumn: "span 1" }}
                    />
                   
                    <FormControl fullWidth>
                      <InputLabel id="status">Status</InputLabel>
                      <Select
                        labelId="Status"
                        id="status"
                        name="status"
                        label="Status"
                        onChange={e => setSt(e.target.value)}
                        sx={{ gridColumn: "span 2" }}
                        value={status}
                      >
                        <MenuItem value={1}>Enabled</MenuItem>
                        <MenuItem value={0}>Disabled</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="gender">Gender</InputLabel>
                      <Select
                        labelId="Gender"
                        id="gender"
                        name="gender"
                        label="Gender"
                        onChange={e => setGen(e.target.value)}
                        sx={{ gridColumn: "span 2" }}
                        value={gen}
                      >
                        <MenuItem value={1}>Male</MenuItem>
                        <MenuItem value={2}>Female</MenuItem>
                        <MenuItem value={3}>Other</MenuItem>
                      </Select>
                    </FormControl>

                    <TextField
                      fullWidth
                      type="text"
                      label="Qualifications *"
                      onBlur={handleBlur}
                      onChange={e => setQua(e.target.value)}
                      value={qua}
                      name="qualifications"
                      sx={{ gridColumn: "span 1" }}
                    />

                    <TextField
                      fullWidth
                      type="text"
                      label="About"
                      onBlur={handleBlur}
                      onChange={e => setAb(e.target.value)}
                      value={about}
                      name="about"
                      sx={{ gridColumn: "span 2" }}
                    />
                    
                    <img src={`https://test1.dstinnovate.com/${pic}`} alt="Doctor's Picture"/>
                    <br />
                    <FormControl fullWidth component="fieldset">
                      <InputLabel>Departments</InputLabel>
                      <br />
                      <br />
                      <br />
                      {departments.map((department) => (
                        <FormControlLabel
                          key={department.id}
                          control={
                            <Checkbox
                              checked={
                                initiallyCheckedDep.includes(department.id) ||
                                dynamicallyCheckedDep.includes(department.id)
                              }
                              disabled={initiallyCheckedDep.includes(
                                department.id
                              )}
                              onChange={(e) => {
                                const checked = e.target.checked;
                                if (!initiallyCheckedDep.includes(department.id)) {
                                  handleDynamicCheckboxChange(
                                    department.id,
                                    checked
                                  );
                                }
                              }}
                            />
                          }
                          label={department.name}
                        />
                      ))}
                    </FormControl>
                    <label>Image Upload</label>
                    <br />
                    <input
                          // hidden
                          accept="image/*"
                          multiple
                          type="file"
                          name="picture"
                          onChange={(event) => {
                            setPic(event.currentTarget.files[0]); // Store the file
                          }}
                        /> 
                  </Box>
                  <br />
                  <Box display="flex" justifyContent="end" mt="20px">
                    <Button type="submit" variant="contained" sx={{color:"white"}}>
                      SAVE
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Card>
      </Box>
    </>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const checkoutSchema = yup.object().shape({
  
});

const initialValues = {
  
};

export default EditDocBasicinfo;
