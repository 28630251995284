import React from 'react'

const Depression = () => {
  return (
    <div>
      Depression
    </div>
  )
}

export default Depression
