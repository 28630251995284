import {
  Box,
  Card,
  Button,
  TextField,
  Typography,
  Breadcrumbs,
  Stack,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import useDetails from "src/hooks/useDetails";
import { useTheme } from "@mui/material/styles";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import * as React from "react";
import { useState } from "react";
import axios from "axios";
import CustomSnackbar from "../../utils/CustomSnackbar";
import useSettings from "../../hooks/useSettings"; // hooks

const ReportIssue = () => {
  const userDetails = useDetails();
  let u_id = userDetails?.user_id;
  let u_role = userDetails?.rolesname[0];

  const [openSnack, setOpenSnack] = useState({
    open: false,
    severity: null,
    message: null,
  });
  const { themeStretch } = useSettings();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();

  const [issue_upload, setUpload] = useState([]);

  const handleFormSubmit = (values) => {
    const formData = new FormData();
    formData.append("issue_description", values.issue_description);
    formData.append("u_id", u_id);
    formData.append("u_role", u_role);

    if (issue_upload.length > 0) {
      for (let i = 0; i < issue_upload.length; i++) {
        formData.append("pictures[]", issue_upload[i]);
      }
    }

    axios
      .post("https://test2.dstinnovate.com/reportinsert", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setOpenSnack({
          open: true,
          severity: "success",
          message: "Issue reported successfully!",
        });
      })
      .catch((error) => {
        console.error("Error reporting issue:", error);
        setOpenSnack({
          open: true,
          severity: "error",
          message: "Something went wrong while reporting the issue.",
        });
      });
  };

  return (
    <>
      {CustomSnackbar(openSnack.open, openSnack.severity, openSnack.message)}
      <Helmet>
        <title>Report an Issue | Kaizen Digital</title>
      </Helmet>
      <Typography variant="h3" sx={{ mb: 0 }} color="primary">
        Report an Issue
      </Typography>

      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to="/">
          Dashboard
        </Link>
        <Link underline="hover" color="inherit" to="">
          Report{" "}
        </Link>
        <Typography color="text.primary">Report an Issue</Typography>
      </Breadcrumbs>
      <br />

      <Card>
        <Box m="20px">
          <Formik
            initialValues={initialValues}
            validationSchema={checkoutSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 6",
                    },
                  }}
                >
                  <Typography variant="h6">
                    Report an Issue related to Kaizen Zenith Platform{" "}
                  </Typography>
                  <Typography variant="body1">
                    We apologize for the inconvenience you faced when using our
                    platform. Please follow the instructions below:
                    <ol style={{ marginLeft: "30px" }}>
                      <li>Tell us when you started facing this issue.</li>
                      <li>
                        Write a detailed description for quicker resolution.
                      </li>
                      <li>Upload a screenshot of the issue you encountered.</li>
                    </ol>
                  </Typography>
                </Box>

                <TextField
                  name="issue_description"
                  value={values.issue_description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  multiline
                  rows={7}
                  fullWidth
                  placeholder="Describe the issue..."
                  error={
                    touched.issue_description &&
                    Boolean(errors.issue_description)
                  }
                  helperText={
                    touched.issue_description && errors.issue_description
                  }
                />
                <Typography><br/></Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{ color: "white" }}
                  >
                    Image Upload
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={(e) => {
                        setUpload(e.target.files);
                      }}
                    />
                  </Button>
                </Stack>
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button type="submit" variant="contained">
                    Report Now
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Card>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  issue_description: yup.string().required("Please provide a description."),
});

const initialValues = {
  issue_description: "",
};

export default ReportIssue;
