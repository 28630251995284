import React, { useState } from "react";
import {
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  Grid,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Hallucinations = () => {
  const theme = useTheme();
  const [delusions, setDelusions] = useState(null);
  const [subQuestions, setSubQuestions] = useState({});
  const [ratings, setRatings] = useState({});

  const handleDelusionsChange = (event, value) => {
    setDelusions(value);

    if (value === "no") {
      setSubQuestions({});
      setRatings({});
    }
  };

  return (
    <Grid container spacing={3} direction="column" style={{ padding: "20px" }}>
      <Grid item>
        <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
          Hallucination Screening
        </Typography>
      </Grid>

      <Grid item>
        <FormControl component="fieldset">
          <Typography variant="h6">Does the patient have Hallucination?</Typography>
          <ToggleButtonGroup
            exclusive
            value={delusions}
            onChange={handleDelusionsChange}
            aria-label="delusions"
          >
            <ToggleButton
              value="yes"
              sx={{
                color: theme.palette.primary.main,
                "&.Mui-selected": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
            >
              Yes
            </ToggleButton>
            <ToggleButton
              value="no"
              sx={{
                color: theme.palette.primary.main,
                "&.Mui-selected": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
            >
              No
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default Hallucinations;
