function encryptPassword(password) {
    let newPassword = password
      .split("")
      .map((_, i) => String.fromCharCode(password.charCodeAt(i) + 1));

    let randomChars =
      "abcdefghijklmnopqrstuvwxyzABCDEEGHIJKLMNOPQRSTUVWXYZ1234567890#@!abcdefghijklmnopqrstuvwxyzABCDEEGHIJKLMNOPQRSTUVWXYZ1234567890#@!abcdefghijklmnopqrstuvwxyzABCDEEGHIJKLMNOPQRSTUVWXYZ1234567890#@!abcdefghijklmnopqrstuvwxyzABCDEEGHIJKLMNOPQRSTUVWXYZ1234567890#@!";

    let shuffled = randomChars
      .split("")
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

    let arr = [];
    let count = 0;
    shuffled.map((item, i) => {
      ++i;
      count <= newPassword.length && arr.push(item);
      if (i % 4 === 0 && count <= newPassword.length) {
        arr[i] = newPassword[count];
        count = count + 1;
      }
    });
    return arr.join("");
  }

  export default encryptPassword;