import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import SignatureCanvas from "react-signature-canvas";
import axios from 'axios';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

// @mui
import { 
  Card,
  Stack,
  Button,
  Box,
  Container,
  Typography,
  Breadcrumbs, 
   TextField
 } from '@mui/material';
 import { Formik} from "formik";
 import { useTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CustomSnackbar from "../../utils/CustomSnackbar";
// hooks
import useSettings from '../../hooks/useSettings';

// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------


const Consent_Doctor = () => {
  const [openSnack, setOpenSnack] = useState({
    open: false,
    severity: null,
    message: null,
  });
    const signRef = useRef(null);
  const [canvas, setCanvas] = useState(null);
  const [canvasVisibility, setCanvasVisibility] = useState(false);
// const {id} = useParams();
  const clearSignatureCanvas = useCallback(() => {
    signRef.current.clear();
    setCanvas(undefined);
    setCanvasVisibility(false);
  }, []);

  const handleGetCanvas = useCallback(() => {
    const canvas = signRef.current.toDataURL();

    setCanvas(canvas);
    setCanvasVisibility(true);
  }, []);

  const [value, setValue] = React.useState(null);

  const isNonMobile = useMediaQuery("(min-width:600px)");
const theme = useTheme();
  const handleFormSubmit = (values) => {
    console.log(values);
  };
  const location = useLocation();
  const [data, setData]= useState([])
  const [patientsig_constentf,setSig] = useState("")
  const [responsiblepartyname_cf, setRP1] = useState("")
  const [date_constentf, setDC] = useState(new Date())
  const [responsibleparty2_cf, setRP2] = useState("")
  const [relationship_consent, setRC] = useState("")
  useEffect(() => {
    axios
      .post(
        "https://qakdci.dstinnovate.com/2222/fetchspatient",JSON.stringify({ patient_id: location.state.id })
      )
      .then((response) => {
        // console.log(id)
        console.log(response)
        setData(response.data);
        setSig(response.data[0].patientsig_constentf)
        setRP1(response.data[0].responsiblepartyname_cf)
        setDC(response.data[0].date_constentf)
        setRP2(response.data[0].responsibleparty2_cf)
        setRC(response.data[0].relationship_consent)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleSave(event, patient_id){
    event.preventDefault()
    axios.put('https://qakdci.dstinnovate.com/2222/consent-s11', {
        patient_id:location.state.id,
        patientsig_constentf:patientsig_constentf,
        responsiblepartyname_cf:responsiblepartyname_cf,
        date_constentf:date_constentf,
        responsibleparty2_cf:responsibleparty2_cf,
        relationship_consent:relationship_consent,
    })
    .then(res => {
      console.log(res)
      {
        setOpenSnack({
          open: true,
          severity: "success",
          message: "Details Saved Successfully",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
        //   navigate("../");
        setOpenSnack({open: false})
        }, 3000);   
      } 
    })
    .catch(err => {
      console.log(err)
      {
        setOpenSnack({
          open: true,
          severity: "error",
          message: "Something went Wrong",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
          // navigate("../");
          setOpenSnack({open: false})
        }, 3000);   
      } 
    }
    )
}

const { themeStretch } = useSettings();
const { open, severity, message, position } = openSnack;

  return (
    <>
        {CustomSnackbar(open, severity, message, position)}

    <Helmet>
        <title> Patients| Kaizen Digital </title>
      </Helmet>
    <Page title="Page Two">
      <Container maxWidth={themeStretch ? false : 'xl'}>
      <Stack direction="column" spacing={0}>
          <Stack direction="column" spacing={0}>
          {/* <Typography variant="h4" gutterBottom color="primary">
            Add Patient       
          </Typography> */}
          <div role="presentation" >
      {/* <Breadcrumbs aria-label="breadcrumb">
        <Typography 
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary">
          Dashboard
        </Typography>
        
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
Patients        </Typography>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
         Add Patient
        </Typography>
      </Breadcrumbs> */}
    </div>
    <Box m="20px">
    <Card>
   
  
   
    <Box m="20px">
    <Helmet>
        <title> Add Patient_1 | Kaizen Digital </title>
      </Helmet>

      <br />
            <Stack direction="column" spacing={0}>
          <Typography color="primary" variant="h4" gutterBottom>
          CONSENT FORM          </Typography>
          
        </Stack>
       
            </Box>
    <Box m="20px">
      
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h6" gutterBottom>
        CONSENT TO MEDICAL CARE AND TREATMENT          </Typography>
        </Stack>
        
    </Box>
    <Box m="20px">
      

      <Formik
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Typography>
            I, <TextField id="standard-basic" label="" value={responsiblepartyname_cf} onChange={(e)=>{setRP1(e.target.value)}} variant="standard" />  hereby consent to the following treatment:
        </Typography><br />
        <Typography>1. Administration and performance of all medical care, diagnostic procedures and treatment;</Typography>
        <Typography>2. Use of prescribed medications;</Typography>
        <Typography> 3. Administration of any needed anesthetics;</Typography>
        <Typography>4. Performance of such procedures or treatments that are deemed necessary or advisable in PATIENT medical care and treatment;</Typography>
        <Typography>5. Performance of diagnostic procedures, tests and cultures; and</Typography>
        <Typography>6. Performance of other medical care, diagnostic tests, procedures and services deemed medically necessary or advisable by my physician or his/her assigned designees. </Typography>
        <br />
        <Typography>I fully understand and agree that this consent is provided voluntarily and in advance of any specific diagnosis, procedure or treatment and that I have the right to refuse these services.</Typography><br />
        <Typography>
        I intend this consent to be continuing even after a specific diagnosis is made and or treatment is recommended. This consent shall remain in full force and effect until revoked by me in writing and will not affect any actions taken prior to my written revocation.
        </Typography><br /><br />
        <strong>B. USE AND DISCLOSURE OF PROTECTED HEALTH INFORMATION</strong><br /><br />
<Typography>I understand and agree that my protected health information will be disclosed to providers and employees within Kaizen Brain Center for purposes of treatment, payment, continuity of care and health care operation purposes and that I have certain rights with respect to my protected health information, as described in the Notice of Privacy Practices, the receipt of which is hereby acknowledged by signing the HIPAA acknowledgement form.</Typography>
<br /><br />
<Typography>By signing below, I agree to the terms of the "Patient Financial Responsibility Policy" document.</Typography>
           <br />
            
{/*            
           <Box m="20px">
    <h4>Digital Sign</h4>
    <div className="sign" style={{width:"500px",height:"200px",border:"2px solid black"}} >
    <SignatureCanvas
        ref={signRef}
        canvasProps={{
          width: 500,
          height: 200
        }}
      />
    </div>
    <div>
    <Button type="submit" onClick={clearSignatureCanvas}  variant="contained"style={{margin:"3vh"}} >
                Clear Signature
              </Button>
    <Button type="submit" onClick={handleGetCanvas}  variant="contained" >
                Submit Signature
              </Button>          
  </div>
  {canvasVisibility && 
  <div style={{display:"flex",width:"600px",height:"300px"}}>
  <img src={canvas} alt="signature" />
  </div>
  } 
</Box> */}



           <Box   display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }} >
                
                <TextField
                fullWidth
                id='sub'
                type="text"
                label="Printed Name:"
                name="printedname"
                value={responsiblepartyname_cf}
                onChange={(e)=>{setRP1(e.target.value)}}
                helperText="Printed Name"
                sx={{ gridColumn: "span 1" }}
              />

<TextField
                fullWidth
                id='sub'
                type="text"
                label="Signature:"
                name="patientsig_constentf"
                value={patientsig_constentf}
                onChange={(e)=>{setSig(e.target.value)}}
                // helperText="Please enter your Full Name"
                sx={{ gridColumn: "span 1" }}
              />
                
                <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Date"
        value={dayjs(date_constentf)}
        sx={{ gridColumn: "span 1" }}
        onChange={(e) => {setDC(e);}}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
             
               <TextField
                fullWidth
                id='sub'
                type="text"
                label="Relationship to Patient"
                name="relapatients"
                value={relationship_consent}
                onChange={(e)=>{setRC(e.target.value)}}
                sx={{ gridColumn: "span 1" }}
                helperText="Relationship to Patient"
              />
              <br />
              <Stack sx={{ gridColumn: "span 3" }}></Stack>
<Stack sx={{ gridColumn: "span 1" }}>
                <Button onClick={handleSave} variant='contained' sx={{width:"220px",  color:"white"}}> SAVE PATIENT DETAILS</Button>
                </Stack>
                    
                    </Box>
                   
          </form>
        )}
      </Formik>
    </Box>
    

    </Card> 
  </Box>
     </Stack>
     
         
        </Stack>
      </Container>
    </Page>
    </>
  );
}



export default Consent_Doctor;
