import AuthContext from "src/contexts/AuthProvider";
import { useContext, useState } from "react";

const useDetails = () => {
  const { auth } = useContext(AuthContext);
  const [apiData, setApiData] = useState({});

  const localData = JSON.parse(localStorage.getItem("user"));

  if (Object.keys(apiData).length) {
    return { ...apiData, setApiData, apiData }; 
  }

  if (Object.keys(auth).length) {
    return { ...auth, setApiData, apiData };
  }

  if (localData) {
    return { ...localData, setApiData, apiData };
  }
};
export default useDetails;