import { useState } from "react";
import { Helmet } from 'react-helmet-async';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

import { Link } from "react-router-dom";
import {
  Select,
  Box,
  Typography,
  Breadcrumbs,
  useTheme,
  Card,
  Button
} from "@mui/material";
import { Stack } from "@mui/system";

// hooks
import useSettings from '../../hooks/useSettings';

// components





const Appointments_Doctor = ({handleAppointment}) => {
  
  const { themeStretch } = useSettings();
  const theme = useTheme();

  const [currentEvents, setCurrentEvents] = useState([]);

  const handleDateClick = (selected) => {
  const title = prompt("Please enter a new title for your event");
  const calendarApi = selected.view.calendar;
  calendarApi.unselect();

    if (title) {
      calendarApi.addEvent({
        id: `${selected.dateStr}-${title}`,
        title,
        start: selected.startStr,
        end: selected.endStr,
        allDay: selected.allDay,
      });
    }
  };

  const handleEventClick = (selected) => {
    if (
      window.confirm(
        `Are you sure you want to delete the event '${selected.event.title}'`
      )
    ) {
      selected.event.remove();
    }
  };

 
  // const handleAppointment = ()=>{
  //   console.log("appointment done");
  // }

  
  return (
   
   

   <Card>
    <Box m="20px">
    <Helmet>
        <title> Appointments | Kaizen Digital </title>
      </Helmet>

      
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h3" gutterBottom color="primary">
            Appointments
          </Typography>

          <Stack direction="row" spacing={2}>
          
          {/* <Link to="/dashboard/appointments/newappointment" >
          <Button variant="contained" spacing={2} sx={{color: "white"}} >
            NEW APPOINTMENT
          </Button>
          </Link>

          <Link to="/dashboard/appointments/editappointment" >
          <Button variant="contained" spacing={2} sx={{color: "white"}} >
            EDIT APPOINTMENT
          </Button>
          </Link> */}

          </Stack>
        </Stack>
        <div role="presentation" >
      <Breadcrumbs aria-label="breadcrumb">
      <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          
Dashboard</Typography>
        
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          
Appointments</Typography>
      </Breadcrumbs>
    </div>
    <br /><br />
    </Box>
   <Box m="20px">

      <Box display="flex" justifyContent="space-between">
        {/* CALENDAR */}
        <Box flex="1 1 100%" ml="15px">
          <FullCalendar
            height="75vh"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            initialView="dayGridMonth"
            editable
            selectable
            selectMirror
            dayMaxEvents
            select={handleDateClick}
            eventClick={handleEventClick}
            eventsSet={(events) => setCurrentEvents(events)}
            
          />
        </Box>
      </Box>
    </Box>
    </Card>
    
 );
};

export default Appointments_Doctor;
