export const filteredNavConfig = (navItems, navConfig) =>
  navConfig.map((config) => {
    const filteredItems = config.items.filter((item) =>
      navItems.includes(item.path)
    );
    const filteredChildrens = config.items
      .map((item1) => {
        const data = item1.children?.filter((item2) =>
          navItems.includes(item2.path)
        );
        if (data?.length > 0) {
          item1.children = item1.children.filter((item3) =>
            data.find((item4) => item4.path === item3.path)
          );
          return {
            ...item1,
          };
        }
      })
      .filter(Boolean);
    return { ...config, items: [...filteredItems, ...filteredChildrens] };
  });
