import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../utils/CustomSnackbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";

const AddPrograms = () => {
  const [users, setUsers] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  const isNonMobile = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    axios.get("https://test3.dstinnovate.com/tmpgraphs")
      .then((response) => setUsers(response.data))
      .catch((error) => console.error("Error:", error));
  }, []);

  const handlePatientChange = (event) => {
    setSelectedPatient(event.target.value);
  };

  const handleProgramChange = (event) => {
    setSelectedProgram(event.target.value);
  };

  const handleSubmit = () => {

    axios.post("https://test1.dstinnovate.com/newassignprogram",
    {
      patient_id: selectedPatient,
      p_name: users.find(user => user.id === selectedPatient)?.pname || "",
      program_name: selectedProgram,
      assigned_date: new Date().toLocaleDateString('en-US'),
    } )
      .then((response) => {
        console.log("Success:", response.data);
        {
          setOpenSnack({
            open: true,
            severity: "success",
            message: "Details Saved Successfully",
            position: ["bottom", "center"],
          });
          setTimeout(() => {
              navigate("../");
            setOpenSnack({ open: false });
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        {
          setOpenSnack({
            open: true,
            severity: "error",
            message: "Something went Wrong",
            position: ["bottom", "center"],
          });
          setTimeout(() => {
            // navigate("../");
            setOpenSnack({ open: false });
          }, 3000);
        }
      });
  };

  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    severity: null,
    message: null,
  });

  const navigate = useNavigate();
  const { open, severity, message, position } = openSnack;

  return (
    <>
          {CustomSnackbar(open, severity, message)}

      <Typography variant="h3" gutterBottom color="primary">
        Assign New Program
      </Typography>
      <Box
        style={{
          border: "2px solid",
          display: "flex",
          justifyContent: "space-evenly",
          borderRadius: "10px",
          padding: "40px",
          margin: "45px",
        }}
      >
        <Box>
          <Typography variant="h6" color={"black"}>
            Please Select a Patient
          </Typography>
          <select
            value={selectedPatient}
            onChange={handlePatientChange}
            style={{
              borderRadius: "5px",
              borderColor: "#CDCDCD",
              color: "#5A5A5A",
            }}
          >
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.pname}
              </option>
            ))}
          </select>
        </Box>
        <Box>
          <Typography variant="h6" color={"black"}>
            Please Select a Program to be Assigned
          </Typography>
          <RadioGroup
            aria-label="program"
            name="program"
            value={selectedProgram}
            onChange={handleProgramChange}
          >
            <FormControlLabel
              value="Kaizen Concussion Program"
              control={<Radio />}
              label="Kaizen Concussion Program"
            />
            <FormControlLabel
              value="Kaizen Headache Program"
              control={<Radio />}
              label="Kaizen Headache Program"
            />
            <FormControlLabel
              value="Kaizen ADHD Program"
              control={<Radio />}
              label="Kaizen ADHD Program"
            />
          </RadioGroup>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" marginTop="20px">
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{ color: "white" }}
        >
          SAVE PROGRAM
        </Button>
      </Box>
    </>
  );
};

export default AddPrograms;