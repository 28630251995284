import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// @mui
import { 
  Card,
  Stack,
  Button,
  Checkbox,
  Box,
  Container,
  Typography,
  Breadcrumbs, 
  TextField, FormControlLabel
 } from '@mui/material';
 import { Formik } from "formik";
 import { useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from 'axios';
import { useState, useEffect } from 'react';
import CustomSnackbar from "../../utils/CustomSnackbar";
import {useLocation} from "react-router-dom";

// hooks
import useSettings from '../../hooks/useSettings';

// components
import Page from '../../components/Page';
import { useParams } from 'react-router-dom';

// ----------------------------------------------------------------------


const Emergency_Doctor = () => {

  // const {id} = useParams()
  const isNonMobile = useMediaQuery("(min-width:600px)");
const theme = useTheme();
const [data, setData] = useState([])
const [relative_friend, setRF] = useState("")
const [relationship_patient, setRP] = useState("")
const [emergency_mobile_no, setEMN] = useState("")
const [emergency_home_phone_no, setEHPN] = useState("")
const [emergency_work_phone_no, setEWPN] = useState("")
const [care_giver, setCG] = useState("")
const [care_giver_emailid,setCGemail] = useState("")
const [visit_reason, setVReason] = useState("")
const [issue_start_date, setStartDate] = useState("")
  const handleFormSubmit = (values) => {
    console.log(values);
  };
  const location = useLocation();

  const [openSnack, setOpenSnack] = useState({
    open: false,
    severity: null,
    message: null,
  });

  useEffect(() => {
    axios
      .post(
        "https://qakdci.dstinnovate.com/2222/fetchspatient",JSON.stringify({ patient_id: location.state.id })
      )
      .then((response) => {
        // console.log(id)
        console.log(response)
        setData(response.data);
        setRF(response.data[0].relative_friend);
        setRP(response.data[0].relationship_patient);
        setEMN(response.data[0].emergency_mobile_no);
        setEHPN(response.data[0].emergency_home_phone_no)
        setEWPN(response.data[0].emergency_work_phone_no)
        setCGemail(response.data[0].care_giver_emailid)
        setCG(response.data[0].care_giver)
        setStartDate(response.data[0].issue_start_date)
        setVReason(response.data[0].visit_reason)
        // setMB(response.data[0].mobile);
        // setDOB(response.data[0].dob);
        // setGEN(response.data[0].gender);
        // setOE(response.data[0].occupation_employer);
        // setSSN(response.data[0].ssn);
        // setMS(response.data[0].marital_status);
        // setADR(response.data[0].postal);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleSave(event, patient_id){
    event.preventDefault()
    axios.put('https://qakdci.dstinnovate.com/2222/emergency-s3', {
        patient_id:location.state.id,
        relative_friend:relative_friend,
        relationship_patient:relationship_patient,
        emergency_mobile_no:emergency_mobile_no,
        emergency_work_phone_no:emergency_work_phone_no,
        emergency_home_phone_no:emergency_home_phone_no,
        care_giver_emailid:care_giver_emailid,
        care_giver:care_giver,
        issue_start_date: issue_start_date,
        visit_reason: visit_reason,
    })
    .then(res => {
      console.log(res)
      {
        setOpenSnack({
          open: true,
          severity: "success",
          message: "Details Saved Successfully",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
        //   navigate("../");
        setOpenSnack({open: false})
        }, 3000);   
      } 
    })
    .catch(err => {
      console.log(err)
      {
        setOpenSnack({
          open: true,
          severity: "error",
          message: "Something went Wrong",
          position: ["bottom", "center"]
        });
        setTimeout(() => {
          // navigate("../");
          setOpenSnack({open: false})
        }, 3000);   
      } 
    }
    )
}

  
 

  const { themeStretch } = useSettings();
  
 
  const { open, severity, message, position } = openSnack;

  return (
    <>
    {CustomSnackbar(open, severity, message, position)}
   
    <Helmet>
        <title> Patients| Kaizen Digital </title>
      </Helmet>
    <Page title="Page Two">
      <Container maxWidth={themeStretch ? false : 'xl'}>
      <Stack direction="column" spacing={0}>
          <Stack direction="column" spacing={0}>
          {/* <Typography variant="h4" gutterBottom color="primary">
            Add Patient       
          </Typography> */}
          <div role="presentation" >
      {/* <Breadcrumbs aria-label="breadcrumb">
        <Typography 
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary">
          Dashboard
        </Typography>
        
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
Patients        </Typography>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
         Add Patient
        </Typography>
      </Breadcrumbs> */}
    </div>
    <Box m="20px">
    <Card>
   
   
    <Box m="20px">
    <Helmet>
        <title> Emergency | Kaizen Digital </title>
      </Helmet>

      <br />
            <Stack direction="column" spacing={0}>
          <Typography color="primary" variant="h4" gutterBottom>
          3. Emergency Contact | Chief Complaint
          </Typography>
          
        </Stack>

    </Box>
    <Box m="20px">
      

      <Formik
        onSubmit={handleFormSubmit}
        
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
            <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
                    <TextField
                fullWidth
                id='relative_friend'
                type="text"
                label="Name of Relative or Friend *"
                name="relative_friend"
                onBlur={handleBlur}
                error={!!touched.relative_friend && !!errors.relative_friend}
                helperText={touched.relative_friend && errors.relative_friend}
                sx={{ gridColumn: "span 1" }}
                value={relative_friend}
                onChange={(e) => setRF(e.target.value)}
              />
                 <TextField
                fullWidth
                id='relationship_patient'
                type="text"
                label="Relationship to Patient *"
                name = "relationship_patient"
                onBlur={handleBlur}
                sx={{ gridColumn: "span 1" }}
                value={relationship_patient}
                onChange={(e) => setRP(e.target.value)}
                error={!!touched.relationship_patient && !!errors.relationship_patient}
                helperText={touched.relationship_patient && errors.relationship_patient}
              />
               <TextField
                fullWidth
                id='sub'
                type="number"
                label="Mobile Number"
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
                value={emergency_mobile_no}
                onChange={(e) => setEMN(e.target.value)}
              />    
               <TextField
                fullWidth
                id='sub'
                type="number"
                label="Home Phone Number"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
                value={emergency_home_phone_no}
                onChange={(e) => setEHPN(e.target.value)}
              />  
               <TextField
                fullWidth
                id='sub'
                type="number"
                label="Work Phone Number"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 1" }}
                value={emergency_work_phone_no}
                onChange={(e) => setEWPN(e.target.value)}
              />     
              <FormControlLabel value={care_giver} control={<Checkbox />} label="Caregiver" />  

              <TextField
                fullWidth
                id='sub'
                type="text"
                label="'Caregiver's Email"
                // error={!!touched.lastName && !!errors.lastName}
                // helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
                value={care_giver_emailid}
                onChange={(e) => setCGemail(e.target.value)}
              />   
              <div
                style={{ background: 'gray', height: '3px', gridColumn: "span 4" }}
              />
                  <TextField
                fullWidth
                id='sub'
                type="text"
                label="Please describe the reason for your visit *"
                onBlur={handleBlur}
                error={!!touched.visit_reason && !!errors.visit_reason}
                helperText={touched.visit_reason && errors.visit_reason}
                sx={{ gridColumn: "span 4" }}
                value={visit_reason}
                onChange={(e) => setVReason(e.target.value)}
              />   
                 <TextField
                fullWidth
                id='sub'
                type="text"
                onBlur={handleBlur}
                label="When did this issue start? *"
                sx={{ gridColumn: "span 4" }}
                name="issue_start_date"
                error={!!touched.issue_start_date && !!errors.issue_start_date}
                helperText={touched.issue_start_date && errors.issue_start_date}
                value={issue_start_date}
                onChange={(e) => setStartDate(e.target.value)}
              />   

<Stack sx={{ gridColumn: "span 3" }}></Stack>
<Stack sx={{ gridColumn: "span 1" }}>
                <Button onClick={handleSave} variant='contained' sx={{color:"white"}}> SAVE PATIENT DETAILS</Button>
                </Stack>

            </Box>
        
          </form>
        )}
      </Formik>
    </Box>
    </Card> 
  </Box>
     </Stack>
     
         
        </Stack>
      </Container>
    </Page>
    </>
  );
}
// const checkoutSchema = yup.object().shape({
//   firstName: yup.string().required("required"),
//   lastName: yup.string().required("required"),
//   email: yup.string().email("invalid email").required("required"),
//   contact: yup
//     .string()
//     .matches(phoneRegExp, "Phone number is not valid")
//     .required("required"),
//   dateofbirth: yup.string().required("required"),
//   password: yup
//     .string()
//     .required("required")
//     .min(8, 'Password must be 8 characters long')
//     .matches(/[0-9]/, 'Password requires a number')
//     .matches(/[a-z]/, 'Password requires a lowercase letter')
//     .matches(/[A-Z]/, 'Password requires an uppercase letter')
//     .matches(/[^\w]/, 'Password requires a symbol'),
//   gender: yup.string().required(),
//   maritalstatus: yup.string(), 
//   occupation: yup.string(),
//   ssn : yup.string(),
//   address1: yup.string().required("required"),
//   address2: yup.string().required("required"),
//   city: yup.string(),
//   country: yup.string(),
//   postal: yup.string(),

// });
// const initialValues = {
//   firstName: "",
//   lastName: "",
//   email: "",
//   contact: "",
//   dateofbirth: "",
//   gender:"",
//  maritalstatus:"",
//  occupation:"",
//  ssn: "", 
//   password: "",
//   address1: "",
//   address2: "",
//   city: "",
//   country: "",
//   postal: "",
// };



export default Emergency_Doctor;
